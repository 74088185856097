<template>
  <div class="add-model-popup">
    <div class="container">
      <template v-if="appliedModels.length">
        <div class="add-model-popup__title">{{ $store.state.options.models.modify_apply_title }}</div>
        <div class="add-model-popup__description">
          <span v-html="$store.state.options.models.modify_apply_text"></span>
          <a href="#" class="link" @click.prevent="deleteAllResources">
            Klicka här för att avbryta alla ansökningar
          </a>
        </div>
      </template>
      <template v-else>
        <div class="add-model-popup__title">{{ $store.state.options.models.new_apply_title }}</div>
        <div class="add-model-popup__description" v-html="$store.state.options.models.new_apply_text"></div>
      </template>
      <div class="row add-model-popup__models">
        <div v-for="(resource, i) in resources" :key="i" class="col-4 col-sm-12 add-model-popup__col">
          <div class="add-model" :class="{active: addedModels.includes(resource.id)}" @click="removeModel(resource.id)">
            <div class="add-model__img">
              <img v-if="resource.profileImage" :src="`${imageUrl}${resource.profileImage.file.thumb.url}`" alt="">
              <!-- <picture v-if="resource.profileImage">
                <source media="max-width: 1679px" :srcset="`${imageUrl}${resource.profileImage.file.large.url}`">
                <source media="max-width: 1024px" :srcset="`${imageUrl}${resource.profileImage.file.medium.url}`">
                <source media="max-width: 767px" :srcset="`${imageUrl}${resource.profileImage.file.thumb.url}`">
                <img :src="`${imageUrl}${resource.profileImage.file.url}`" alt="">
              </picture> -->
            </div>
            <div v-if="resource.resourceType === 'model' || resource.resourceType === 'human'" class="add-model__info">
              <div class="add-model__name">{{ resource.resourceModel.firstName }} {{ resource.resourceModel.ageInYears }}</div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a :href="`${userFrontendBaseUrl}/account/resources/${resource.id}/`" class="link add-model__link">Se</a>
              </div>
            </div>
            <!-- <div v-if="resource.resourceType === 'animal'" class="add-model__info">
              <div class="add-model__name">{{ resource.resourceAnimal.name }} {{ resource.resourceAnimal.ageInYears }}</div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a :href="`${userFrontendBaseUrl}/account/resources/${resource.id}/`" class="add-model__link">View</a>
              </div>
            </div>
            <div v-if="resource.resourceType === 'location'" class="add-model__info">
              <div class="add-model__name">{{ resource.resourceLocation.name }} {{ resource.resourceLocation.buildYear }}</div>
              <div class="add-model__type">
                <span>
                  {{ resource.resourceType }}
                </span>
                <a :href="`${userFrontendBaseUrl}/account/resources/${resource.id}/`" class="add-model__link">View</a>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-4 col-sm-12 add-model-popup__col">
          <div class="add-model add-new btn" @click="addNew">+ Lägg till ny</div>
        </div>
      </div>
      <div class="add-model-popup__submit">
        <button class="btn btn--primary" type="submit" @click.prevent="submitHandler">
          ok
        </button>
      </div>
    </div>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import ExistingResources from "@/graphql/existingResources.query.gql";
import DeleteCastingApplications from "@/graphql/deleteCastingApplications.mutation.gql";
import ApplyExistingResources from "@/graphql/applyExistingResources.mutation.gql";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { ref } from 'vue';
export default {
  props: {
    appliedModels: Array,
    modelTypes: Array,
    castingID: Number
  },
  emits: ["casting-modified", "update:applyShow", "update:showPopup"],
  components: {
    ErrorPopup
  },
  setup (props) {
    const removedModels = ref([]);
    const errorMessage = ref('');
    const showErrorPopup = ref(false);
    const addedModels = ref([]);
    props.appliedModels.forEach(el => {
      addedModels.value.push(el);
    })
    const {result} = useQuery(ExistingResources, {resourceTypes: props.modelTypes});
    const resources = useResult(
      result,
      [],
      data => data.user.userModel.resources
    );
    const imageUrl = process.env.VUE_APP_API_URL;
    const userFrontendBaseUrl = process.env.VUE_APP_USER_FRONTEND_BASE_URL;

    const { mutate: deleteCastingApplications } = useMutation(DeleteCastingApplications);
    const { mutate: applyResources } = useMutation(ApplyExistingResources);

    return {
      removedModels,
      errorMessage,
      showErrorPopup,
      addedModels,
      resources,
      imageUrl,
      deleteCastingApplications,
      applyResources,
      userFrontendBaseUrl
    }
  },
  methods: {
    removeModel(id) {
      const index = this.addedModels.indexOf(id);
      if(index > -1) {
        this.addedModels.splice(index, 1)
        if(this.appliedModels.indexOf(id) > -1) {
          this.removedModels.push(id)
        }
      } else {
        const deletedIndex = this.removedModels.indexOf(id)
        if(deletedIndex > -1 && this.appliedModels.indexOf(id) > -1) {
          this.removedModels.splice(deletedIndex, 1)
        };
        this.addedModels.push(id);
      }
    },
    deleteResources() {
      if(!this.removedModels.length) return;
      this.deleteCastingApplications({
        input: {
          resourceIds: this.removedModels,
          castingId: this.castingID
        }
      })
      .then(({data}) => {
        if(data.deleteResourceCasting.errors) {
          this.errorMessage = data.deleteResourceCasting.errors.message;
          this.showErrorPopup = true;
          return
        }
        this.$emit('casting-modified');
      })
    },
    deleteAllResources() {
      // if(!this.removedModels.length) return;
      this.deleteCastingApplications({
        input: {
          resourceIds: this.appliedModels,
          castingId: this.castingID
        }
      })
      .then(({data}) => {
        if(data.deleteResourceCasting.errors) {
          this.errorMessage = data.deleteResourceCasting.errors.message;
          this.showErrorPopup = true;
          return
        }
        this.$emit('casting-modified');
        this.$emit("update:applyShow", false);
      })
    },
    compareArrays(arr1,arr2) {
      if(!arr1  || !arr2) return false;
      if(arr1.length !== arr2.length) return false;
      let result;
      arr1.forEach(el1 => {
        if(arr2.indexOf(el1) > -1){
          result = true;
        }else {
          return false;
        }
      })
      return result
    },
    submitHandler() {
      this.deleteResources();
      if (this.compareArrays(this.addedModels, this.appliedModels)) return;
      this.applyResources({ input: {
        resourceIds: this.addedModels,
        castingId: this.castingID
      } })
      .then(({data}) => {
        if(data.createResourceCasting.errors) {
          this.errorMessage = data.createResourceCasting.errors.message;
          this.showErrorPopup = true;
          return
        }
        this.$emit("casting-modified", true);
        this.$emit("update:applyShow", false);
      })
    },
    addNew() {
      this.$emit("update:showPopup", true);
      this.$emit("update:applyShow", false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/add-model.scss";
</style>