<template>
  <div class="confirmation-page">
    <div class="container">
      <div class="confirmation">
        <h1 class="confirmation__title">Ansökan mottagen!</h1>
        <div class="confirmation__content" v-html="text"></div>
        <div class="confirmation__close">
          <button class="btn btn--primary" type="button" @click="closeClick">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    confirmation: Boolean,
    text: String,
  },
  emits: ['update:confirmation'],
  methods: {
    closeClick() {
      this.$emit("update:confirmation", false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/jobs/confirmation.scss";
</style>