import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Registration from "../views/Registration.vue";
import Signup from "../views/Signup.vue";
import SetPassword from "../views/SetPassword.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Login from "../views/Login.vue";
import Jobs from "../views/Jobs.vue";
import Job from "../views/Job.vue";
import Simple from "../views/SimplePage.vue";
import Project from "../views/Project.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: SetPassword,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/jobs/:id",
    name: "Job",
    component: Job,
    props: true,
  },
  {
    path: "/portfolio/:slug",
    name: "Project",
    component: Project,
  },
  {
    path: "/:slug",
    name: "Simple",
    component: Simple,
  },
];

const userFrontendBaseUrl = process.env.VUE_APP_USER_FRONTEND_BASE_URL;

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ top: 0 });
        }
      }, 1000);
    });
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const guests = [
    "Login",
    "SetPassword",
    "ResetPassword",
    "ForgotPassword",
  ];
  if (window.localStorage.getItem("user") && guests.includes(to.name)) {
    location.href = userFrontendBaseUrl;
    return;
  }
});

export default router;
