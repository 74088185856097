<template>
  <div class="row faq">
    <div class="col-3 col-sm-12 faq__links">
      <div class="faq__links-inner">
        <a
          v-for="cat of categories"
          :key="cat"
          :class="{ active: active == cat.link }"
          @click.prevent="scrollTo(cat.link)"
          href="#"
        >
          {{ cat.title }}
        </a>
      </div>
    </div>
    <div class="col-9 col-sm-12">
      <div
        v-for="cat of categories"
        :key="cat.link"
        :id="cat.link"
        class="faq__category"
      >
        <div class="faq__category-title">
          {{ cat.title }}
        </div>
        <div v-html="cat.content" class="faq__content page__content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["categories"],
  data: () => ({
    active: 0,
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      let toActive = "";
      this.categories.forEach((cat) => {
        let observer = document
          .getElementById(cat.link)
          .getBoundingClientRect();
        if (observer.top < window.innerHeight) {
          toActive = cat.link;
        }
      });
      this.active = toActive;
    },
    scrollTo(id) {
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.faq {
  &__links {
    position: relative;
    &-inner {
      position: sticky;
      top: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        font-size: 20px;
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid transparent;
        &:hover {
          color: $violet;
          border-color: $violetBorder;
        }
        &.active {
          border-color: $violetBorder;
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 10px;
      margin-bottom: 24px;
      border-bottom: 1px solid $violetBorder;
      &-inner {
        position: relative;
        top: 0;
        a {
          font-size: 14px;
        }
      }
    }
  }
  &__category {
    padding-bottom: 50px;
    padding-top: 80px;
    margin-top: -80px;

    &-title {
      font-weight: 700;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
}
</style>