<template>
  <div class="portfolios">
    <h2 class="content-title">Tidigare jobb</h2>
    <div class="container portfolios__container">
      <div class="portfolios__row row">
        <div
          v-for="(portfolio, index) in portfolios"
          :key="index"
          class="col-3 col-lg-4 col-md-6 col-sm-12 portfolios__col"
        >
          <router-link
            :to="`/portfolio/${portfolio.slug}`"
            class="portfolios__item portfolios-item"
          >
            <div class="portfolios-item__img">
              <img
                @load="imgLoaded"
                :srcset="portfolio.acf.thumbnail.srcset"
                :src="portfolio.acf.thumbnail.sizes.large"
                alt=""
              />
            </div>
            <div class="portfolios-item__title">
              {{ portfolio.title.rendered }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const portfolios = computed(() =>
      store.state.portfolios.filter((el) => el.acf.featured === true)
    );
    const progress = ref(0);
    const loadedImages = ref(0);
    const isPageReady = ref(false);

    onMounted(() => {
      store.dispatch("onFetchPortfolios");
    });

    return {
      portfolios,
      progress,
      loadedImages,
      isPageReady,
    };
  },
  methods: {
    imgLoaded() {
      this.loadedImages++;
      this.progress = (this.loadedImages / this.portfolios.length) * 100;
      this.$emit("update:progress", this.progress);
      this.$nextTick(() => {
        if (this.loadedImages >= this.portfolios.length) {
          this.isPageReady = true;
          this.$emit("update:isPageReady", this.isPageReady);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/portfolios.scss";
</style>
