<template>
  <div
    class="hero"
    :style="
      padding ? `padding: ${0.2033 * padding}px 0 ${0.179 * padding}px` : ''
    "
  >
    <div class="hero__container container" ref="heroContent">
      <div class="hero__content">
        <img
          class="hero__image"
          :srcset="hero.image.srcset"
          :src="hero.image.sizes.large"
          alt=""
        />
        <h1 class="hero__title" @click="clickHandler">
          {{ hero.title }}
        </h1>
        <div
          v-if="hero && hero.subtitle"
          class="hero__text"
          v-html="hero.subtitle"
        ></div>
      </div>
      <div class="hero__btn">
        <router-link to="/jobs" class="btn btn--primary">Casting</router-link>
        <a href="content-start" @click.prevent="scrollPageTo" class="btn btn--primary-bordered">Läs mer</a>
      </div>
    </div>
    <!-- <div class="hero__bg" :style="bgImage"></div> -->
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    hero: { type: Object },
  },
  emits: ["update:progress", "update:imagesReady"],
  setup() {
    const isDesktop = window.innerWidth > 767 ? ref(true) : ref(false);

    const progress = ref(0);
    const imagesReady = ref(false);
    const loadedImages = ref(0);
    const scrollPageTo = (e) => {
      let element = document.getElementById(`${e.target.getAttribute("href")}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const padding = ref("");
    // const preloadImage = (imageUrl) => {
    //   return new Promise((resolve, reject) => {
    //     const image = new Image();
    //     image.onload = () => {
    //       resolve(imageUrl);
    //     };
    //     image.onerror = (er) => reject(er);
    //     image.src = imageUrl;
    //   });
    // };

    return {
      scrollPageTo,
      isDesktop,
      padding,
      progress,
      loadedImages,
      imagesReady,
      // preloadImage,
    };
  },
  mounted() {
    // if (!this.isDesktop) {
    //   this.padding = window.innerHeight - this.$refs.heroContent.clientHeight;
    //   this.padding = 0.2033 * this.padding > 68 ? this.padding : "";
    //   if (this.hero.mobileBackground) {
    //     this.preloadImage(this.hero.mobileBackground.url).then(() => {
    //       this.loadedImages++;
    //       this.progress = (this.loadedImages / 1) * 100;
    //       this.$emit("update:progress", this.progress);
    //       this.$nextTick(() => {
    //         if (this.loadedImages >= 1) {
    //           this.imagesReady = true;
    //           this.$emit("update:imagesReady", this.imagesReady);
    //         }
    //       });
    //     });
    //   } else {
    //     this.preloadImage(this.hero.background.url).then(() => {
    //       this.loadedImages++;
    //       this.progress = (this.loadedImages / 1) * 100;
    //       this.$emit("update:progress", this.progress);
    //       this.$nextTick(() => {
    //         if (this.loadedImages >= 1) {
    //           this.imagesReady = true;
    //           this.$emit("update:imagesReady", this.imagesReady);
    //         }
    //       });
    //     });
    //   }
    // } else {
      // this.preloadImage(this.hero.image.url).then(() => {
      //   this.loadedImages++;
      //   this.progress = (this.loadedImages / 1) * 100;
      //   this.$emit("update:progress", this.progress);
      //   this.$nextTick(() => {
      //     if (this.loadedImages >= 1) {
      //       this.imagesReady = true;
      //       this.$emit("update:imagesReady", this.imagesReady);
      //     }
      //   });
      // });
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/hero.scss";
</style>
