<template>
  <div class="page">
    <RandomBg :whiteBg="projectName === 'miine'" />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        <!-- Loading <span>{{ Math.round(progress) }}%</span> -->
        Loading <span>...</span>
      </div>
    </transition>
    <Header />
    <login-form :redirect="true" v-if="$store.state.options">
      <template v-slot:subtitle>
        <div v-html="$store.state.options.miine.login_text" v-if="projectName === 'miine'"></div>
        <div v-html="$store.state.options.auth.login_text" v-if="projectName !== 'miine'"></div>
      </template>
    </login-form>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    RandomBg,
    Header,
    LoginForm,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const isPageReady = ref(false);

    const projectName = route?.query?.from === 'miine' ? 'miine' : 'miini';
    localStorage.setItem('domain', projectName);
    store.dispatch('onFetchProjectName', projectName);

    onMounted(() => {
      setTimeout(() => {
        isPageReady.value = true;
      }, 200);
    });

    return {
      isPageReady,
      projectName
    };
  },
};
</script>
