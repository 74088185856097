<template>
  <div class="home">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    
    <Header />
    <div class="home-hero">
      <Hero v-if="isPageReady" :hero="homePage.acf.hero" />
    </div>
    <ModelsGrid v-if="isPageReady" :title="homePage.acf.contentTitle" />
    <how-works v-if="isPageReady" :content="homePage.acf.howWorks"></how-works>
    <featured-jobs v-if="$store.state.options"/>
    <Portfolios v-model:progress="progress" v-model:isPageReady="isPageReady" />
    <work-with-us v-if="isPageReady" :work="homePage.acf.work"></work-with-us>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import Hero from "@/components/home/Hero.vue";
import ModelsGrid from "@/components/home/ModelsGrid.vue";
import HowWorks from "@/components/home/HowWorks.vue";
import WorkWithUs from "@/components/home/Work.vue";
import FeaturedJobs from "@/components/FeaturedJobs.vue";
import Portfolios from "@/components/home/Portfolios.vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: {
    RandomBg,
    Header,
    Hero,
    ModelsGrid,
    HowWorks,
    WorkWithUs,
    FeaturedJobs,
    Portfolios,
  },
  setup() {
    const store = useStore();
    const progress = ref(0);
    const isDesktop = window.innerWidth > 767 ? ref(true) : ref(false);
    const isPageReady = ref(false);
    const homePage = computed(() => store.state.pageHome);

    onMounted(() => {
      store.dispatch("onFetchHome");
    });
    
    return {
      progress,
      isDesktop,
      isPageReady,
      homePage,
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  z-index: 1;
  &-back {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  &-hero {
    @media screen and (max-width: 767px) {
      overflow-y: auto;
    }
  }
}
</style>
