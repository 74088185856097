<template>
  <div class="page job">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        <!-- Loading <span>{{ Math.round(progress) }}%</span> -->
        Loading <span>...</span>
      </div>
    </transition>
    <Header />
    <div class="container" v-if="$store.state.options">
      <featured-jobs @jobs-changed="fetchJobs" />
      <div class="job__action" v-html="$store.state.options.jobs.text"></div>
    </div>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import { onMounted, ref } from "vue";
import FeaturedJobs from "@/components/FeaturedJobs.vue";
export default {
  components: {
    RandomBg,
    Header,
    FeaturedJobs,
  },
  setup() {
    const isPageReady = ref(false);
    onMounted(() => {
      setTimeout(() => {
        // progress.value = 100;
        isPageReady.value = true;
      }, 200);
    });

    return {
      isPageReady,
    };
  },
  methods: {
    fetchJobs(jobs) {
      this.jobs = jobs;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.job {
  &__action {
    font-size: 20px;
    line-height: 1.33;
    text-align: center;
    max-width: 856px;
    margin: 38px auto 0;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 1.54;
      margin-top: 31px;
    }
  }
  &__link {
    border-bottom: 1px solid $violetBorder;
    transition: color 0.3s;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $violet;
      }
    }
  }
}
.jobs.empty + .job__action {
  display: none;
}
</style>
