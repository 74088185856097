<template>
  <div class="jobs" :class="{empty: !featuredJobs.length}">
    <div class="container jobs__container">
      <h2 class="jobs__title content-title" v-if="featuredJobs.length">{{ $store.state.options.jobs.title }}</h2>
      <h2 class="jobs__title content-title" v-else>{{ $store.state.options.jobs.no_jobs_title }}</h2>
      <div class="jobs__content row">
        <div class="col-2 col-sm-12"></div>
        <div class="col-8 col-sm-12">
          <router-link v-for="job in featuredJobs" :key="job.node.id" :to="`/jobs/${job.node.id}`" class="jobs__item jobs-item">
            <h5 class="jobs-item__title">
              <span>{{ job.node.name }}</span>
            </h5>
            <div class="jobs-item__info">
              <span>Ansök innan {{ (new Date(job.node.until)).toISOString().slice(0,10) }}</span>
            </div>
          </router-link>
          <div class="job__action" v-if="!featuredJobs.length" v-html="$store.state.options.jobs.no_jobs_text"></div>
        </div>
        <div class="col-2 col-sm-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
import featuredJobsQuery from "@/graphql/featuredjobs.query.gql";
import { useQuery, useResult } from "@vue/apollo-composable";
export default {
  setup() {
    const variables = {
      resourceType: "all",
      order: "latest",
      featured: true,
      first: 4,
    };
    const { result } = useQuery(featuredJobsQuery, variables);
    const featuredJobs = useResult(result, [], (data) => data.castings.edges);

    return {
      featuredJobs,
    };
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/featuredJobs.scss";
.job__action {
  max-width: 856px;
  margin: 0 auto;
}
</style>
