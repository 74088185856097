import { createApp, h, provide } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VuelidatePlugin } from "@vuelidate/core";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { createHead } from "@vueuse/head";

const link = new createUploadLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
  headers: {
    "Accept-Language": (process.env.VUE_APP_API_LOCALE || "en")
  }
});
const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem(process.env.VUE_APP_JWT_TOKEN_NAME);
  if(token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        "JWT-AUD": "web"
      }
    }
  } else {
    return {
      headers: {
        ...headers
      }
    }
  }
})
const defaultClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache()
})
const head = createHead();
createApp({
  setup() {
    provide(DefaultApolloClient, defaultClient)
  },
  render() {
    return h(App);
  }
})
  .use(store)
  .use(router)
  .use(VuelidatePlugin)
  .use(head)
  .mount("#app");
