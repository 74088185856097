<template>
  <div class="page project">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />

    <suspense>
      <Project v-model:progress="progress" v-model:isPageReady="isPageReady" />
    </suspense>
  </div>
</template>

<script>
import { ref } from "vue";

import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import Project from "@/components/Project.vue";

export default {
  components: {
    RandomBg,
    Header,
    Project,
  },
  setup() {
    const isPageReady = ref(false);
    const progress = ref(0);
    const loadedImages = ref(0);
    return {
      isPageReady,
      progress,
      loadedImages,
    };
  },
};
</script>

<style lang="scss" scoped></style>
