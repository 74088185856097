<template>
  <div class="container models" id="content-start">
    <h2 class="content-title" v-html="title"></h2>
    <div class="models-grid" v-if="models">
      <div class="models-grid__wrap">
        <div
          v-for="(item, index) in models"
          :key="index"
          class="models-grid__item"
        >
          <div
            class="models-grid__img"
            :class="{ 'is-empty': !item.node.profileImage }"
          >
            <img
              v-if="item.node.profileImage"
              :src="`${imageUrl}/${item.node.profileImage.file.thumb.url}`"
              alt=""
              @load="imgLoaded"
            />
            <img
              v-else
              style="display: none"
              :src="require(`@/assets/img/content/${defaultImageName}`)"
              alt=""
              @load="imgLoaded"
            />
          </div>
          <div v-if="item.node.resourceModel" class="models-grid__title">
            {{ item.node.resourceModel.firstName }}
            {{ item.node.resourceModel.ageInYears }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import FeaturedModelsQuery from "@/graphql/featuredModels.query.gql";
export default {
  emits: ["update:progress", "update:imagesReady"],
  props: {
    title: { type: String },
  },
  setup() {
    const loadedImages = ref(0);
    const progress = ref(0);
    const imagesReady = ref(false);
    const imageUrl = process.env.VUE_APP_API_URL;
    const defaultImageName = "default-placeholder-image.png";
    const limit = ref(window.matchMedia("(max-width: 1100px)").matches ? 4 : 5);
    const { result } = useQuery(FeaturedModelsQuery);
    const allModels = useResult(
      result,
      [],
      (data) => data.featuredModels.edges
    );
    const models = computed(() => allModels.value.slice(0, limit.value));

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1100) {
        if (limit.value == 5) {
          limit.value = 4;
        }
      } else {
        if (limit.value == 4) {
          limit.value = 5;
        }
      }
    });

    return {
      loadedImages,
      progress,
      imagesReady,
      imageUrl,
      defaultImageName,
      models,
    };
  },
  methods: {
    imgLoaded() {
      this.loadedImages++;
      this.progress = (this.loadedImages / this.models.length) * 100;
      this.$emit("update:progress", this.progress);
      this.$nextTick(() => {
        if (this.loadedImages >= this.models.length) {
          this.imagesReady = true;
          this.$emit("update:imagesReady", this.imagesReady);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/castings/models.scss";
</style>
