<template>
  <div class="model-form">
    <div class="form-group" :class="{ error: v.modelHuman.modelName.$errors.length }">
      <label class="form-label" for="modelName">Förnamn</label>
      <input
        v-model="modelHuman.modelName"
        class="form-input"
        type="text"
        placeholder="Modellens förnamn"
      />
      <p
        v-for="(error, index) of v.modelHuman.modelName.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="form-group" :class="{ error: v.modelHuman.modelLastName.$errors.length }">
      <label class="form-label" for="modelLastName">Efternamn</label>
      <input
        v-model="modelHuman.modelLastName"
        class="form-input"
        type="text"
        placeholder="Modellens efternamn"
      />
      <p
        v-for="(error, index) of v.modelHuman.modelLastName.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div
      class="form-group"
      :class="{ error: v.modelHuman.modelBirth.$errors.length }"
    >
      <label class="form-label" for="modelBirth">Födelsedatum</label>
      <input
        v-model="modelHuman.modelBirth"
        class="form-input"
        type="date"
        placeholder="Modellens födelsedatum"
      />
      <p
        v-for="(error, index) of v.modelHuman.modelBirth.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="form-group" :class="{ error: v.modelHuman.modelGender.$errors.length }">
      <label class="form-label" for="modelGender">Kön</label>
      <custom-select
        :options="genderList.map((e) => (e.title ? e.title : null))"
        :tabindex="1"
        :defaultValue="'Modellens kön'"
        :error="v.modelHuman.modelGender.$error"
        @input="
          (event) => {
            this.modelHuman.modelGender = genderList.filter(
              (e) => e.title === event
            )[0].slug;
          }
        "
      />
      <p
        v-for="(error, index) of v.modelHuman.modelGender.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="form-group" :class="{ error: v.modelHuman.modelHeight.$errors.length }">
      <label class="form-label" for="modelHeight">Längd</label>
      <input
        v-model="modelHuman.modelHeight"
        class="form-input"
        type="text"
        placeholder="Modellens längd"
      />
      <p
        v-for="(error, index) of v.modelHuman.modelHeight.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="form-group" :class="{ error: v.modelHuman.modelAddress.$errors.length }">
      <label class="form-label">Ort</label>
      <address-autocomplete
        v-model:modelData="modelHuman.modelAddress"
        :placeholder="'Modellens primära boendeort'"
      />
      <p
        v-for="(error, index) of v.modelHuman.modelAddress.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
    <div class="form-group" :class="{ error: v.modelHuman.images.$errors.length }">
      <div class="form-label">Bilder</div>
      <DragAndDrop
        v-model:images="modelHuman.images"
        v-model:resourceImageIds="modelHuman.resourceImageIds"
        resourceType="model"
        @image-added="imageAdded"
        @image-uploaded="imageUploaded"
      />
      <p
        v-for="(error, index) of v.modelHuman.images.$errors"
        :key="index"
        class="error-message"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { helpers, numeric, required, integer } from "@vuelidate/validators";
import DragAndDrop from "@/components/DragAndDrop";
import addressAutocomplete from "@/components/AddressAutocomplete.vue";
// import Autocomplete from "@/components/Autocomplete.vue";
import CustomSelect from "@/components/CustomSelect";
import useVuelidate from "@vuelidate/core";
export default {
  components: {
    DragAndDrop,
    CustomSelect,
    addressAutocomplete,
  },
  props: {
    submitClick: Boolean,
    model: Object,
  },
  emits: ["update:model", "modelUpdate", "image-added", "image-uploaded"],
  setup(props, { emit }) {
    const modelHuman = reactive({
      modelType: "human",
      modelName: "",
      modelLastName: "",
      modelBirth: "",
      modelGender: "",
      modelHeight: "",
      modelAddress: "",
      images: [],
      resourceImageIds: [],
    });
    const genderList = [
      {
        title: "Tjej",
        slug: "female",
      },
      {
        title: "Kille",
        slug: "male",
      },
      {
        title: "Icke-binär",
        slug: "non_binary",
      },
      {
        title: "Vill inte svara",
        slug: "undisclosed",
      },
    ];
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    watch(
      () => props.submitClick,
      (newValue) => {
        if (newValue) {
          emit("update:model", modelHuman);
          emit("modelUpdate");
        }
      }
    );
    const requiredErrorMessage = "Fältet får inte vara tomt";
    const numericErrorMessage = "Detta fält ska vara numeriskt";
    const minHeightErrorMessage = "Längd måste vara större än eller lika med 1";
    const maxHeightErrorMessage = "Höjd måste vara mindre än eller lika med 250";
    const integerHeightErrorMessage = "Height must be integer";
    const yearErrorMessage = "Datum inte giltigt";
    const imagesErrorMessage = "Ladda upp minst ett foto";
    const rules = {
      modelHuman: {
        modelName: {
          required: helpers.withMessage(requiredErrorMessage, required),
          $autoDirty: true,
        },
        modelLastName: {
          required: helpers.withMessage(requiredErrorMessage, required),
          $autoDirty: true,
        },
        modelBirth: {
          required: helpers.withMessage(yearErrorMessage, required),
          $autoDirty: true,
        },
        modelGender: {
          required: helpers.withMessage(requiredErrorMessage, (value) =>
            value ? true : false
          ),
          $autoDirty: true,
        },
        modelHeight: {
          required: helpers.withMessage(requiredErrorMessage, (value) =>
            value ? true : false
          ),
          numeric: helpers.withMessage(numericErrorMessage, numeric),
          minValue: helpers.withMessage(
            minHeightErrorMessage,
            (value) => value >= 1
          ),
          maxValue: helpers.withMessage(
            maxHeightErrorMessage,
            (value) => value <= 250
          ),
          integer: helpers.withMessage(integerHeightErrorMessage, integer),
          $autoDirty: true,
        },
        modelAddress: {
          required: helpers.withMessage(requiredErrorMessage, (value) =>
            value ? true : false
          ),
          $autoDirty: true,
        },
        images: {
          required: helpers.withMessage(
            imagesErrorMessage,
            (arr) => arr.length !== 0
          ),
          $autoDirty: true,
        },
        // modelInstagram: {
        // url: helpers.withMessage(urlErrorMessage, url),
        // $autoDirty: true
        // }
      }
    };
    const v = useVuelidate(rules, {
      modelHuman,
    });
    return {
      modelHuman,
      genderList,
      imageAdded,
      imageUploaded,
      v,
    };
  },
};
</script>
