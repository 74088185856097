<template>
  <input
    v-model="location"
    type="search"
    autocomplete="off"
    class="form-input"
    ref="inputField"
    :placeholder="placeholder"
    @input="$emit('update:modelData', this.location)"
  />
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    placeholder: String,
    modelData: String
  },
  emits: ["update:modelData"],
  setup() {
    const location = ref("");
    window.checkAndAttachMapScript = function(callback) {
      if (window.google) {
        // If you're using vue cli, then directly checking 'google' obj will throw an error at the time of transpiling.
        callback();
        return true;
      }

      window.mapApiInitialized = callback;
      let script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places,geometry&callback=mapApiInitialized`;
      document.body.appendChild(script);
    };
    return {
      location
    };
  },
  mounted() {
    // window.checkAndAttachMapScript(this.initLocationSearch);
    window.checkAndAttachMapScript(this.MapsInit);
  },
  // metaInfo() {
  //   return{
  //     script: [{
  //       src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyBq_NUeQHbIp3P6Q1BrKfKgciLxvLaByug&libraries=places`,
  //       async: true,
  //       defer: true,
  //       callback: () => this.MapsInit() // will declare it in methods
  //     }]
  //   }
  // },
  methods: {
    MapsInit() {
      let autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.inputField
      );
      autocomplete.addListener("place_changed", () => {
        let place = autocomplete.getPlace();
        if (place && place.address_components) {
          this.location = this.$refs.inputField.value;
          this.$emit("update:modelData", this.location);
        }
      });
    },
    changeResult(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  appearance: none!important;
  border-radius: 0!important;
}
</style>
