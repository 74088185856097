<template>
  <h3
    class="form__subtitle flex-title"
    :style="Object.keys(currentUser).length ? 'margin-top: 0px;' : ''"
  >
    <span>
      {{ $store.state.options.models.form_title }}
      <span v-for="(type, index) in modelTypes" :key="index">
        <!-- {{ type }}<span v-if="index === modelTypes.length - 2">  </span><span v-else-if="index < modelTypes.length - 1">, </span> -->
        <!-- model<span v-if="index === modelTypes.length - 2">  </span><span v-else-if="index < modelTypes.length - 1">, </span> -->
        <span v-if="index === modelTypes.length - 2">  </span><span v-else-if="index < modelTypes.length - 1">, </span>
      </span>
      {{ index > 0 ? `  ${index + 1}` : "" }}
    </span>
    <button
      v-if="index > 0"
      class="info remove-button"
      type="button"
      @click="$emit('delete', index)"
    >
      Ta bort
    </button>
  </h3>
  <div class="form__block-description" v-html="$store.state.options.models.form_text"></div>
  <!-- <div v-if="model.modelType === 'human'" class="form__block-description">
    Please add details and upload 8 to 10 fresh photos of yourself. If you're
    uncertain of what kind of photos to choose, please take a look here:
    <a href="#" @click.prevent="$emit('show-popup', model.modelType)">
      What images should I choose?
    </a>
  </div>
  <div v-else-if="model.modelType === 'animal'" class="form__block-description">
    Please add details and upload 8 to 10 fresh photos of your animal. If you're
    uncertain of what kind of photos to choose, please take a look here:
    <a href="#" @click.prevent="$emit('show-popup', model.modelType)">
      What images should I choose?
    </a>
  </div>
  <div
    v-else-if="model.modelType === 'location'"
    class="form__block-description"
  >
    Please add details and upload 8 to 10 fresh photos of your house, apartment
    or location. If you're uncertain of what kind of photos to choose, please
    take a look here:
    <a href="#" @click.prevent="$emit('show-popup', model.modelType)">
      What images should I choose?
    </a>
  </div> -->
  <!-- <div>
    <div v-if="modelTypes.length > 1">
      <div v-for="(type, key) in modelTypes" :key="key" class="form-group radio-button">
        <input
          v-model="model.modelType"
          type="radio"
          :name="`type${index}`"
          :id="`model${index}`"
          :value="type"
          @change="$emit('update:modelData', model)"
        />
        <label :for="`model${index}`">{{ type }}</label>
      </div>
    </div> -->
  <!-- <div class="form-group radio-button">
      <input
        v-model="model.modelType"
        type="radio"
        :name="`type${index}`"
        :id="`animal${index}`"
        value="animal"
        @change="$emit('update:modelData', model)"
      />
      <label :for="`animal${index}`">Animal</label>
    </div>
    <div class="form-group radio-button">
      <input
        v-model="model.modelType"
        type="radio"
        :name="`type${index}`"
        :id="`location${index}`"
        value="location"
        @change="$emit('update:modelData', model)"
      />
      <label :for="`location${index}`">Location</label>
    </div> -->
  <!-- </div> -->
  <!-- <div class="form-group radio-button">
    <input
      v-model="model.modelType"
      type="radio"
      :name="`type${index}`"
      :id="`compain${index}`"
      value="compain"
      @change="$emit('update:modelData', model)"
      disabled
    />
    <label :for="`compain${index}`">Compaing</label>
  </div> -->
  <human-model
    v-if="model.modelType === 'human' || model.modelType === 'model'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
    @image-added="imageAdded"
    @image-uploaded="imageUploaded"
  />
  <!-- <animal-model
    v-else-if="model.modelType === 'animal'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
  />
  <location-model
    v-else-if="model.modelType === 'location'"
    :submitClick="submitClick"
    v-model:model="model"
    @model-update="$emit('update:modelData', model)"
  /> -->
  <div class="info">
    <a class="link" @click.prevent="$emit('show-popup', model.modelType)">
      Viktigt att tänka på när ni skickar in bilder!
    </a>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import HumanModel from "@/components/forms/HumanModel.vue";
// import AnimalModel from "@/components/jobs/AnimalModel.vue";
// import LocationModel from "@/components/jobs/LocationModel.vue";
import { useStore } from "vuex";
export default {
  components: {
    HumanModel,
    // AnimalModel,
    // LocationModel
  },
  props: {
    modelData: Object,
    index: Number,
    submitClick: Boolean,
    modelTypes: Array,
  },
  emits: [
    "update:modelData",
    "showPopup",
    "delete",
    "image-added",
    "image-uploaded",
  ],
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const model = reactive({
      modelType: props.modelTypes[0],
    });
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }

    return {
      model,
      currentUser,
      imageAdded,
      imageUploaded
    };
  },
};
</script>
