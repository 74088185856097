<template>
  <form class="form" id="form">
    <div class="form__container container">
      <h1 class="content-title page__title" v-if="options">{{ options.auth.login_title }}</h1>
      <div class="page__description">
        <slot name="subtitle"></slot>
      </div>
      <div>
        <div
          class="form-group"
          :class="{ error: v.emailAddress.$errors.length }"
        >
          <label class="form-label">E-mail</label>
          <input
            v-model="emailAddress"
            class="form-input"
            type="text"
            placeholder="Fyll i e-mail"
          />
          <p
            v-for="(error, index) of v.emailAddress.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="form-group" :class="{ error: v.password.$errors.length }">
          <label class="form-label">Lösenord</label>
          <input
            v-model="password"
            class="form-input"
            type="password"
            placeholder="Fyll i lösenord"
          />
          <p
            v-for="(error, index) of v.password.$errors"
            :key="index"
            class="error-message"
          >
            {{ error.$message }}
          </p>
        </div>
      </div>
      <div class="form__submit">
        <button
          :disabled="v.$invalid"
          class="btn btn--primary"
          type="submit"
          @click.prevent="submitHandler"
        >
          Logga in
        </button>
        <div class="form__footer">
          <label class="checkbox">
            <input
              v-model="politic"
              class="checkbox__input"
              type="checkbox"
              name="politic"
            />
            <div class="checkbox__icon"></div>
            <div class="checkbox__label">Kom ihåg mig</div>
          </label>
        </div>
        <div class="info">
          <router-link class="link" :to="'/forgot-password' + ($store.state.projectName === 'miine' ? '?from=miine' : '')">
            Glömt lösenord?
          </router-link>
        </div>
      </div>
    </div>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </form>
</template>

<script>
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import { nextTick, ref, computed } from "vue";
import { useStore } from "vuex";
import { useMutation } from "@vue/apollo-composable";
import signInMutation from "@/graphql/signIn.mutation.gql";

export default {
  props: {
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ErrorPopup,
  },
  emits: ["loged-in", "update:login"],
  setup() {
    const store = useStore();
    const options = computed(() => store.state.options);
    const emailAddress = ref("");
    const password = ref("");
    const requiredErrorMessage = "Fältet får inte vara tomt";
    const emailErrorMessage = "E-postadressen är inte giltig";
    const politic = ref(false);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const { mutate: signIn } = useMutation(signInMutation, {
      context: {
        headers: {
          "JWT-AUD": "web",
        },
      },
    });
    const rules = {
      emailAddress: {
        required: helpers.withMessage(requiredErrorMessage, (value) =>
          value ? true : false
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true,
      },
      password: {
        required: helpers.withMessage(requiredErrorMessage, required),
        $autoDirty: true,
      },
    };
    const v = useVuelidate(rules, {
      emailAddress,
      password,
    });

    return {
      emailAddress,
      password,
      politic,
      signIn,
      showErrorPopup,
      errorMessage,
      store,
      options,
      v,
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }
      nextTick(() => {
        this.signIn({
          input: {
            email: this.emailAddress,
            password: this.password,
          },
        })
          .then(({ data }) => {
            if (!data.signIn.session) {
              this.errorHandler(data);
              return;
            }
            const token = data.signIn.session.token;
            window.localStorage.setItem(
              process.env.VUE_APP_JWT_TOKEN_NAME,
              token
            );
            if (data.signIn.session.user) {
              window.localStorage.setItem(
                "user",
                JSON.stringify(data.signIn.session.user)
              );
              if (!this.redirect) {
                this.store.dispatch("onFetchUser", data.signIn.session.user);
              }
              this.$emit("loged-in", false);
            }
            if (this.redirect) {
              window.location.href = "/my";
            }
          })
          .catch((e) => {
            const message =
              e.networkError?.result?.errors
                ?.map((error) => error.message)
                ?.join(", ") || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.submitClick = false;
      window.localStorage.removeItem(process.env.VUE_APP_JWT_TOKEN_NAME);
      this.showErrorPopup = true;
      if (error.signIn.errors) {
        this.errorMessage = `${error.signIn.errors[0].message}`;
        return;
      }
      this.errorMessage =
        "Something went wrong...\nPlease check email and password";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/login/login.scss";
</style>
