<template>
  <div v-if="imagesUrls.length" class="preview-gallery">
    <div
      v-for="(image, key) in imagesUrls"
      :key="key"
      class="preview-gallery__item"
    >
      <img class="preview-gallery__image" :src="image" alt="" />
      <button
        type="button"
        class="preview-gallery__remove"
        @click="removeImage(key)"
      >
        <svg
          class="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 14 14"
        >
          <path
            d="M14,1.4L12.6,0L7,5.6L1.4,0L0,1.4L5.6,7L0,12.6L1.4,14L7,8.4l5.6,5.6l1.4-1.4L8.4,7L14,1.4z"
          />
        </svg>
      </button>
    </div>
  </div>
  <div
    v-if="isDesktop"
    class="drop-area"
    ref="dropArea"
    :class="{ highlight: highlight }"
  >
    <p>
      Dra och släpp bilder här för att ladda upp, eller
      <label class="upload-file-label">
        klicka här
        <input
          type="file"
          class="fileElem"
          multiple
          accept="image/*"
          @change="inputChange"
        />
      </label>
    </p>
  </div>
  <div v-else>
    <label class="info upload-file-label">
      + Lägg till bilder
      <input
        type="file"
        class="fileElem"
        multiple
        accept="image/*"
        @change="inputChange"
      />
    </label>
  </div>
</template>

<script>
import { ref } from "vue";
import uploadResourceImageMutation from "@/graphql/uploadResourceImage.mutation.gql";
import { useMutation } from "@vue/apollo-composable";
export default {
  props: {
    images: Array,
    resourceImageIds: Array,
    resourceType: String
  },
  emits: [
    "update:images",
    "image-uploaded",
    "image-added",
    "update:resourceImageIds"
  ],
  setup(props, { emit }) {
    const isDesktop = window.innerWidth > 767 ? ref(true) : ref(false);
    const files = ref([]);
    const highlight = ref(false);
    const imagesUrls = ref([]);
    const { mutate: uploadImage } = useMutation(uploadResourceImageMutation);
    const resultObject = ref({});
    const imagesIds = ref([]);

    function removeImage(index) {
      imagesUrls.value.splice(index, 1);
      checkDeletion();
      function checkDeletion() {
        if (imagesIds.value.length === files.value.length) {
          const IDtoDelete = Object.keys(resultObject.value).find(
            key => resultObject.value[key] === files.value[index]
          );
          if (IDtoDelete) {
            files.value.splice(index, 1);
            imagesIds.value.splice(imagesIds.value.indexOf(IDtoDelete), 1);
            delete resultObject.value[IDtoDelete];
            emit("image-uploaded", -1);
            emit("image-added", -1);
          }
        } else {
          setTimeout(checkDeletion, 200);
        }
      }
    }
    function previewFile(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        imagesUrls.value.push(reader.result);
      };
    }
    function saveFile(file) {
      files.value.push(file);
      previewFile(file);
      emit("image-added", 1);
      uploadImage({ file: file, resourceType: "model" })
        .then((response) => {
          resultObject.value[
            response.data.uploadResourceImage.resourceImage.id
          ] = file;
          imagesIds.value.push(
            response.data.uploadResourceImage.resourceImage.id
          );
          emit("image-uploaded", 1);
          emit("update:resourceImageIds", imagesIds.value);
        })
        .catch(error => {
          console.log(error);
        });
      emit("update:images", files.value);
    }
    return {
      files,
      highlight,
      imagesUrls,
      removeImage,
      isDesktop,
      uploadImage,
      resultObject,
      imagesIds,
      saveFile
    };
  },
  methods: {
    handleDrop(e) {
      const dt = e.dataTransfer;
      const files = dt.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      [...files].forEach(this.saveFile);
    },
    highlightArea() {
      this.highlight = true;
    },
    unhighlightArea() {
      this.highlight = false;
    },
    inputChange(e) {
      this.handleFiles(e.target.files);
      e.target.value = '';
    },
    // addAttachment(item) {
    //   return new Promise((resolve, reject) => {
    //     let results = [];
    //     this.uploadImage({ file: item })
    //       .then(response => {
    //         this.resultObject[
    //           response.data.uploadResourceImage.resourceImage.id
    //         ] = item;
    //         this.imagesIds.push(
    //           response.data.uploadResourceImage.resourceImage.id
    //         );
    //         results = true;
    //         resolve(results);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // }
  },
  mounted() {
    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (this.isDesktop) {
      const dropArea = this.$refs.dropArea;
      ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });
      ["dragenter", "dragover"].forEach(eventName => {
        dropArea.addEventListener(eventName, this.highlightArea, false);
      });
      ["dragleave", "drop"].forEach(eventName => {
        dropArea.addEventListener(eventName, this.unhighlightArea, false);
      });
      dropArea.addEventListener("drop", this.handleDrop, false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/dragAndDrop.scss";
</style>
