<template>
  <div class="page job-page">
    <RandomBg />
    <!-- LOADERS -->
    <transition name="miiniLoader" mode="out-in">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        Loading <span>...</span>
      </div>
    </transition>
    <transition name="fadetext">
      <div v-if="sendingData" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="sendingData" class="loader-progress">
        <div
          class="loader-progress__info"
          :style="addedImages ? '' : 'justify-content:center;'"
        >
          <label>Vänta, laddar upp </label>
          <label v-if="addedImages">
            {{ Math.round((100 * uploadedImages) / addedImages) }}%
          </label>
        </div>
        <div v-if="addedImages" class="loader-progress__background">
          <div
            class="loader-progress__tracker"
            :style="`width: ${(100 * uploadedImages) / addedImages}%`"
          ></div>
        </div>
      </div>
    </transition>

    <Header />

    <div class="container" v-if="Object.keys(job).length && $store.state.options">
      <div class="row">
        <div class="col-3 col-sm-12"></div>
        <div class="col-6 col-sm-12">
          <div class="job-page__title content-title">{{ job.name }}</div>
          <div class="job-page__info" v-if="job.until">
            <span>
              Ansök innan
              {{ new Date(job.until).toISOString().slice(0, 10) }}
            </span>
          </div>
          <template v-if="job.running">
            <div
              v-if="job.introText"
              class="job-page__description"
              v-html="job.introText.replaceAll('\n', '<br />')"
            />
          </template>
          <template v-else>
            <div
              v-if="job.disabledText"
              class="job-page__description"
              v-html="job.disabledText.replaceAll('\n', '<br />')"
            />
          </template>
          <div class="info-banner">
            <strong>{{ homePage.acf.imagesPopupHeader }}</strong>
            <br />
            <br />
            <div
              v-html="homePage.acf.imagesPopupTexts.humanText"
              class="wysiwyg"
            ></div>
          </div>
          <!-- <div v-if="appliedResources.length" class="job-page__applied applied-block">
            <div class="applied-block__title">
              You have applied to this casting with
              <span v-for="(item, index) in appliedResources" :key="index">
                <a v-if="item.resourceType === 'model'" :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`">{{ item.resourceModel.firstName }}</a>
                <a v-if="item.resourceType === 'animal'" :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`">{{ item.resourceAnimal.name }}</a>
                <a v-if="item.resourceType === 'location'" :href="`${userFrontendBaseUrl}/account/resources/${item.id}/`">{{ item.resourceLocation.name }}</a>
                <span v-if="index === appliedResources.length - 2"> and </span><span v-else-if="index < appliedResources.length - 1">, </span>
              </span>
            </div>
            <div class="applied-block__text">
              <a :href="`${userFrontendBaseUrl}`">Check your profile page</a> to sure the details are up to date and the images are recent.
              Press the button below to cancel or modify application.
            </div>
          </div> -->
          <template v-if="job.running">
            <div v-if="!currentUser.id || currentUser.role != 'model'" class="job-page__actions row">
              <div class="col-6 col-lg-12 job-action">
                <div class="job-action__title">Inte medlem?<br />Ansök här</div>
                <div class="job-action__btn">
                  <button class="btn btn--primary" @click="showPopup = true">Ansök</button>
                </div>
              </div>
              <div v-if="!currentUser.id" class="col-6 col-lg-12 job-action">
                <div class="job-action__title">
                  Redan medlem?<br />Logga in för att ansöka
                </div>
                <div class="job-action__btn">
                  <button class="btn btn--primary" @click="login = true">Logga in</button>
                </div>
              </div>
            </div>
            <div v-else class="job-page__actions">
              <div class="job-action">
                <div class="job-action__btn">
                  <button class="btn btn--primary" @click="applyShow = true">
                    <span v-if="!appliedResources.length">Ansök</span>
                    <span v-else>Ändra ansökan</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div class="job-page__contact" v-html="$store.state.options.jobs.job_bottom_info"></div>
        </div>
        <div class="col-3 col-sm-12"></div>
      </div>
    </div>
    <NotFound v-else />

    <teleport to="[data-popup]">
      <div v-if="showPopup" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="showPopup = !showPopup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <apply-form
              :title="$store.state.options.models.logged_apply_title"
              :imagesHeader="homePage.acf.imagesPopupHeader"
              :texts="homePage.acf.imagesPopupTexts"
              :modelTypes="['human']"
              :castingID="job.id"
              v-model:confirmation="confirmation"
              v-model:showPopup="showPopup"
              @added-new="modelsAdded"
              :dataReady="dataReady"
              @sending="sendingHandler"
              @image-added="imageAdded"
              @image-uploaded="imageUploaded"
            />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-popup]">
      <div v-if="applyShow" class="job-popup">
        <div class="job-popup__wrap">
          <button type="button" class="popup__close" @click="applyShow = !applyShow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <apply-models-popup
              :modelTypes="job.resourceTypes"
              :appliedModels="addedModels"
              :castingID="job.id"
              v-model:applyShow="applyShow"
              v-model:showPopup="showPopup"
              @casting-modified="modelsApplied"
            />
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-popup]">
      <div v-if="login" class="job-popup">
        <div class="job-popup__wrap">
          <button type="button" class="popup__close" @click="login = !login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <div class="login-popup">
              <login-form @loged-in="logedIn" v-model:login="login">
                <template v-slot:title> Logga in för att ansöka </template>
              </login-form>
            </div>
          </div>
        </div>
      </div>
    </teleport>
    <teleport to="[data-error-popup]">
      <div v-if="confirmation" class="job-popup">
        <div class="job-popup__wrap">
          <button
            type="button"
            class="popup__close"
            @click="confirmation = !confirmation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
          <div class="job-popup__container">
            <confirmation-popup v-model:confirmation="confirmation" :text="job.thankYouText"/>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import ApplyForm from "@/components/forms/ApplyForm.vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import ApplyModelsPopup from "@/components/popups/ApplyModelsPopup.vue";
import ConfirmationPopup from "@/components/popups/ConfirmationPopup.vue";
import NotFound from "@/components/NotFound.vue";

import { computed, onMounted, reactive, ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import jobQuery from "@/graphql/job.query.gql";
import castingAppliedResources from "@/graphql/castingAppliedResources.query.gql";
export default {
  props: {
    id: [String, Number],
    presetJob: Object,
  },
  components: {
    RandomBg,
    Header,
    ApplyForm,
    LoginForm,
    ApplyModelsPopup,
    ConfirmationPopup,
    NotFound,
  },
  setup(props) {
    const isPageReady = ref(false);
    onMounted(() => {
      store.dispatch("onFetchHome");
      setTimeout(() => {
        isPageReady.value = true;
      }, 200);
    });
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const homePage = computed(() => store.state.pageHome);
    const firstLoading = ref(true);
    const login = ref(false);
    const userFrontendBaseUrl = process.env.VUE_APP_USER_FRONTEND_BASE_URL;
    const showPopup = ref(false);
    const applyShow = ref(false);
    const confirmation = ref(false);
    const dataReady = ref(false);
    const sendingData = ref(false);
    const addedImages = ref(0);
    const uploadedImages = ref(0);
    const formatDate = (date) => {
      const formatter = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      return formatter.format(date);
    };
    const variables = reactive({
      id: props.id,
    });

    let job;
    if (props.presetJob) {
      job = computed(() => props.presetJob);
    } else {
      const { result } = useQuery(jobQuery, variables, {
        fetchPolicy: "cache-and-network",
      });
      job = useResult(result, [], (data) => data.casting);
    }

    const variablesCastingQuery = reactive({
      castingId: +props.id,
    });
    useHead({
      title: computed(() => `Miini | ${job.value.name}`),
      meta: [
        {
          name: 'og:title',
          content: computed(() => `Miini | ${job.value.name}`),
        }
      ]
    })
    const appliedResult = useQuery(
      castingAppliedResources,
      variablesCastingQuery
    );
    const refetchResources = appliedResult.refetch;
    const appliedResources = useResult(
      appliedResult.result,
      [],
      (data) => data.user.userModel.resources
    );
    const addedModels = computed(() =>
      appliedResources.value.map((el) => el.id)
    );

    return {
      isPageReady,
      job,
      firstLoading,
      formatDate,
      login,
      currentUser,
      userFrontendBaseUrl,
      showPopup,
      applyShow,
      appliedResources,
      addedModels,
      refetchResources,
      confirmation,
      homePage,
      sendingData,
      dataReady,
      addedImages,
      uploadedImages,
    };
  },
  methods: {
    logedIn(event) {
      this.login = event;
      this.refetchResources();
    },
    sendingHandler(event) {
      this.sendingData = event;
      if (this.sendingData && !this.addedImages) {
        this.dataReady = true;
      }
    },
    imageAdded(value) {
      this.addedImages += value;
      if (this.uploadedImages !== this.addedImages) {
        this.dataReady = false;
      }
    },
    imageUploaded(value) {
      this.uploadedImages += value;
      if (this.uploadedImages === this.addedImages) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.dataReady = true;
          }, 300);
        });
      }
    },
    modelsAdded() {
      this.showPopup = false;
      if (this.currentUser.id) {
        this.applyShow = true;
      } else {
        this.confirmation = true;
      }
      this.isPageReady = true;
      this.refetchResources();
    },
    modelsApplied() {
      this.confirmation = true;
      this.refetchResources();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.job {
  &-page {
    padding-top: 130px;
    @media screen and (max-width: 767px) {
      padding-top: 90px;
    }
    &__title {
      text-align: center;
      line-height: 1.28;
      @media screen and (max-width: 767px) {
        line-height: 1.34;
      }
    }
    &__info {
      text-align: center;
      margin-top: 6px;
      @media screen and (max-width: 767px) {
        font-size: 13px;
        line-height: 1.54;
        margin-top: 28px;
      }
    }
    &__description {
      line-height: 1.6;
      margin-top: 35px;
      @media screen and (max-width: 767px) {
        font-size: 13px;
        line-height: 1.54;
        margin-top: 28px;
      }
    }
    &__actions {
      padding-top: 56px;
      @media screen and (max-width: 767px) {
        padding-top: 63px;
      }
    }
    &__contact {
      margin-top: 57px;
      line-height: 1.6;
      @media screen and (max-width: 767px) {
        font-size: 13px;
        line-height: 1.54;
        margin-top: 68px;
      }
      // a {
      //   transition: color 0.3s;
      //   text-decoration: underline;
      //   @media (hover: hover) and (pointer: fine) {
      //     &:hover {
      //       // color: $hoverColor;
      //       color: $btnBg;
      //     }
      //   }
      // }
    }
  }
  &__action {
    // margin-top: 115px;
    max-width: 465px;
    margin: 51px auto 0;
    line-height: 1.6;
    text-align: center;
  }
  &__link {
    text-decoration: underline;
    transition: color 0.3s;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $violet;
      }
    }
  }
}
.job-action {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1259px) {
    &:not(:first-child) {
      margin-top: 28px;
    }
  }
  &__title {
    flex: 1 1 auto;
    font-size: calc(17px + (30 - 17) * ((100vw - 375px) / (1680 - 375)));
    line-height: 1.34;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 1.54;
    }
  }
  &__btn {
    &:not(:first-child) {
      margin-top: 38px;
      @media screen and (max-width: 767px) {
        margin-top: 32px;
      }
    }
  }
}
.job-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 10;
  font-size: 0;
  text-align: center;
  overflow: auto;
  @media only screen and (max-width: 767px) {
    padding: 12px;
  }
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
  }
  &__wrap {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    max-width: 856px;
  }
  &__container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 5;
    background-color: #fff;
    font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1680 - 375)));
    text-align: left;
    .white & {
      background-color: #fff;
    }
    @media only screen and (max-width: 767px) {
      // padding: 24px 24px 36px 24px;
      font-size: 15px;
    }
    // .error-popup & {
    //   padding: 23px 32px 38px;
    //   @media only screen and (max-width: 767px) {
    //     padding: 28px 16px 44px 16px;
    //   }
    // }
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 15.56px;
    height: 15.56px;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    z-index: 10;
    @media only screen and (max-width: 767px) {
      width: 13px;
      height: 13px;
      top: 10px;
      right: 10px;
    }
    &:after {
      content: "";
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .form {
    padding: 32px 0 0;
    @media screen and (max-width: 767px) {
      padding: 48px 0 0;
    }
  }
}
.info-banner {
  font-size: 20px;
  line-height: 1.6;
  margin: 64px -32px 0;
  padding: 48px 32px;
  border: 4px solid #DFD3FD;

  @media screen and (max-width: 767px) {
    font-size: 13px;
    margin: 36px -16px 0;
    padding: 26px 16px 40px;
  }
}
</style>
