<template>
  <header class="header">
    <div class="container header__container">
      <router-link to="/" class="header__link header__link_left">
        <!-- miini. -->
        <img :src="require(`@/assets/img/miine.svg`)" alt="" v-if="currentProject === 'miine'" />
        <img :src="require(`@/assets/img/miini.svg`)" alt="" v-if="currentProject === 'miini'" />
      </router-link>
      <div class="header__menu" v-if="currentProject === 'miini'">
        <transition name="fade">
          <ul class="desktop-nav" v-if="isDesktop && !menuState">
            <li class="desktop-nav__item">
              <router-link
                class="desktop-nav__link"
                active-class="active"
                to="/jobs"
              >
                Casting
              </router-link>
            </li>
            <li class="desktop-nav__item">
              <router-link
                class="desktop-nav__link"
                active-class="active"
                to="/signup"
              >
                Bli kund
              </router-link>
            </li>
          </ul>
        </transition>
        <button
          type="button"
          class="toggle-menu js-toggle-menu"
          @click="sandwichHandler"
        >
          <span
            class="sandwich js-sandwich"
            :class="{ 'is-active': menuState }"
          >
            <span class="sw-topper"></span>
            <span class="sw-bottom"></span>
            <span class="sw-footer"></span>
          </span>
        </button>
      </div>
      <transition name="fade">
        <ul class="main-menu" v-if="menuState">
          <li class="main-menu__item">
            <ul class="main-nav">
              <li class="main-nav__item">
                <a class="main-nav__link" href="/my" v-if="currentUser.id">
                  {{ currentUser.firstName }}
                </a>
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/login"
                  v-else
                >
                  Logga in
                </router-link>
              </li>
            </ul>
          </li>
          <li class="main-menu__item">
            <ul class="main-nav">
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/jobs"
                >
                  Casting
                </router-link>
              </li>
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/signup"
                >
                  Bli kund
                </router-link>
              </li>
            </ul>
          </li>
          <li class="main-menu__item">
            <ul class="main-nav">
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/about-us"
                >
                  Om oss
                </router-link>
              </li>
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/faq"
                >
                  Frågor och svar
                </router-link>
              </li>
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/contact"
                >
                  Kontakt
                </router-link>
              </li>
              <!-- <li class="main-nav__item">
								<router-link v-if="!Object.keys(currentUser).length" class="main-nav__link" active-class="active" to="/">Login</router-link>
								<span v-else-if="isDesktop" class=""> Logged in as {{ currentUser.firstName }}</span>
							</li> -->
            </ul>
          </li>
          <li class="main-menu__item">
            <ul class="main-nav">
              <li class="main-nav__item">
                <a
                  class="main-nav__link"
                  href="https://www.facebook.com/www.miini.se"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li class="main-nav__item">
                <a
                  class="main-nav__link"
                  href="https://www.instagram.com/miini.stockholm/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </li>
          <li class="main-menu__item main-menu__item_legal">
            <ul class="main-nav legal-nav">
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/medlemsvillkor"
                >
                  Medlemsvillkor
                </router-link>
              </li>
              <li class="main-nav__item">
                <router-link
                  class="main-nav__link"
                  active-class="active"
                  to="/cookies"
                >
                  Cookies
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </transition>
      <!-- <router-link to="/" class="header__link header__link_right">MO</router-link> -->
    </div>
  </header>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const menuState = ref(false);
    const isDesktop = window.innerWidth > 1023 ? ref(true) : ref(false);
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const currentProject = store.state.projectName;

    return {
      menuState,
      isDesktop,
      currentUser,
      currentProject,
    };
  },
  methods: {
    sandwichHandler() {
      this.menuState = !this.menuState;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  transition: color 0.3s;
  z-index: 10;
  &.white {
    color: #fff;
  }
  &__container {
    height: 80px;
    padding: 0 0 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 676px) {
      padding: 0 0 0 24px;
    }
  }
  &__link {
    font-size: 30px;
    line-height: 32px;
    pointer-events: all;
    z-index: 1;
    @media screen and (max-width: 390px) {
      font-size: 25px;
    }
    &_left {
      left: 0;
      width: 115px;
      @media screen and (max-width: 767px) {
        width: 86px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    &_right {
      right: 0;
    }
  }
  &__menu {
    pointer-events: all;
    z-index: 1;
    display: flex;
    align-items: center;
  }
}
.desktop-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  &__item {
    font-size: 20px;
    line-height: 1.33;
    &:not(:first-child) {
      margin-left: 32px;
      @media only screen and (max-width: 1023px) {
        margin: 0;
      }
    }
  }
  &__link {
    display: block;
    margin-top: 2px;
    border-bottom: 1px solid transparent;
    &:hover {
      color: $violet;
      border-color: transparent;
    }
    &.active {
      border-color: $violetBorder;
    }
  }
}
.main-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  top: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  width: 308px;
  background-color: $violet;
  padding: 25px 55px 42px 32px;
  @media screen and (max-width: 767px) {
    width: 192px;
    padding: 20px 52px 20px 15px;
  }
  &__item {
    &:not(:first-child) {
      margin-top: 2em;
    }
    &_legal {
      &:not(:first-child) {
        margin-top: auto;
        padding-top: 32px;
      }
    }
  }
}
.main-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: all;
  overflow: auto;
  font-size: 20px;
  line-height: 35px;
  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 26px;
  }
  &__item {
    &:nth-child(n + 2) {
      margin-top: 7px;
    }
  }
  &__link {
    display: inline-block;
    border-bottom: 1px solid transparent;
    &:hover {
      color: #fff;
      border-color: transparent;
    }
    &.active {
      border-color: #fff;
    }
  }
}
// .secondary-nav {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   font-size: 20px;
//   margin-top: 28px;
//   @media screen and (max-width: 390px) {
//     font-size: 12px;
//     line-height: 2.67;
//   }
// }
.legal-nav {
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
</style>
<style lang="sass" scoped>
/*Sandwich*/
.toggle-menu
	position: relative
	float: right
	width: 80px
	height: 80px
	display: flex
	align-items: center
	justify-content: center
	z-index: 100
	border: none
	background-color: transparent
	@media only screen and (max-width: 767px)
		width: 64px
		height: 64px

$w: 15px
$h: 3px

.sandwich
	position: absolute
	left: 0
	right: 0
	margin: auto
	z-index: 200
	top: 50%
	transform: translateY(-50%)
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
.sw-topper
	display: block
	position: relative
	transform-origin: center center
	width: $w
	height: $h
	background: currentColor
	border: none
	transition: transform 0.3s
	.header.is-fixed &,
	.header.is-open &
		background-color: #000
.sw-bottom
	overflow: hidden
	display: block
	position: relative
	transition: transform 0.3s
	width: $w
	height: $h
	background: currentColor
	margin: 3px 0
.sw-footer
	display: block
	position: relative
	width: $w
	height: $h
	transform-origin: center center
	background: currentColor
	border: none
	transition: transform 0.3s
	.header.is-fixed &,
	.header.is-open &
		background-color: #363636

.sandwich.is-active
	.sw-topper
		width: 18.5px
		transform: translateY(6px) rotate(45deg)
	.sw-bottom
		transform: scale(0)
	.sw-footer
		width: 18.5px
		transform: translateY(-6px) rotate(-45deg)
</style>
