<template>
  <div class="random-bg" ref="bg">
    <svg v-bind:viewBox="bgViewbox">
      <polygon :points="bgPoints" fill="#FFFFFF" v-if="whiteBg" />
      <polygon :points="bgPoints" fill="#FDFAF3" v-if="!whiteBg" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    whiteBg: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bgWidth: 0,
    bgHeight: 0,
  }),
  mounted() {
    this.calcBackground();
    new ResizeObserver(this.calcBackground).observe(this.$refs.bg);
  },
  computed: {
    bgViewbox: (vm) => `0 0 ${vm.bgWidth} ${vm.bgHeight}`,
    bgPoints: (vm) => `
      ${rand(50)},${rand(50)}
      ${vm.bgWidth - rand(50)},${rand(50)}
      ${vm.bgWidth - rand(49)},${vm.bgHeight - rand(49)}
      ${rand(49)},${vm.bgHeight - rand(49)}
    `,
  },
  methods: {
    calcBackground() {
      this.bgWidth = this.$el.clientWidth;
      this.bgHeight = this.$el.clientHeight;
    }
  }
};

function rand(max) {
  return Math.floor(Math.random() * max);
}
</script>

<style lang="scss" scoped>
.random-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
}
</style>
