<template>
  <div>
    <div v-if="project" class="container portfolio-content">
      <div class="row">
        <div class="col-3 col-sm-12"></div>
        <div class="col-6 col-sm-12">
          <div class="portfolio-content__title content-title">
            {{ project.title.rendered }}
          </div>
          <div
            v-if="project.acf.description"
            class="portfolio-content__description"
            v-html="project.acf.description"
          ></div>
          <div v-if="project.acf.images" class="portfolio-content__images">
            <div
              v-for="(image, index) in project.acf.images"
              :key="index"
              class="portfolio-content__img"
            >
              <img
                :srcset="image.image.srcset"
                :src="image.image.url"
                alt=""
                @load="imgLoaded"
              />
            </div>
          </div>
        </div>
        <div class="col-3 col-sm-12">
          <button class="portfolio-content__back" @click="goBack">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.556"
              height="15.556"
              viewBox="0 0 15.556 15.556"
            >
              <rect
                width="3"
                height="19"
                transform="translate(0 2.121) rotate(-45)"
              />
              <rect
                width="3"
                height="19"
                transform="translate(2.121 15.556) rotate(-135)"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <NotFound v-else />
  </div>
</template>

<script>
import NotFound from "@/components/NotFound.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    NotFound,
  },
  async setup() {
    const route = useRoute();
    const project = await fetch(
      `${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/posts?slug=${route.params.slug}`
    )
      .then(res => res.json())
      .then(res => res[0]);
    const progress = ref(0);
    const loadedImages = ref(0);
    const isPageReady = ref(false);

    return {
      project,
      progress,
      loadedImages,
      isPageReady,
    };
  },
  mounted() {
    if (!this.project || !this.project.acf.images) {
      this.progress = 100;
      this.$emit("update:progress", this.progress);
      this.isPageReady = true;
      this.$emit("update:isPageReady", this.isPageReady);
    }
  },
  methods: {
    imgLoaded() {
      this.loadedImages++;
      this.progress =
        (this.loadedImages / this.project.acf.images.length) * 100;
      this.$emit("update:progress", this.progress);
      this.$nextTick(() => {
        if (this.loadedImages >= this.project.acf.images.length) {
          this.isPageReady = true;
          this.$emit("update:isPageReady", this.isPageReady);
        }
      });
    },
    goBack(e) {
      e.preventDefault();
      if (!window.history.state.back) {
        this.$router.push(`/`);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/portfolio/project.scss";
</style>
