<template>
  <div class="page">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <Header />
    <div class="form signup" v-if="$store.state.options">
      <div class="form__container container">
        <h2 class="content-title page__title">{{ $store.state.options.auth.signup_title }}</h2>
        <div class="page__description" v-html="$store.state.options.auth.signup_text"></div>
        <div class="signup__wrap">
          <div class="form-group" :class="{ error: v.user.firstName.$errors.length }">
            <label class="form-label">Förnamn</label>
            <input
              v-model="user.firstName"
              class="form-input"
              type="text"
            />
            <p
              v-for="(error, index) of v.user.firstName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.lastName.$errors.length }">
            <label class="form-label">Efternamn</label>
            <input
              v-model="user.lastName"
              class="form-input"
              type="text"
            />
            <p
              v-for="(error, index) of v.user.lastName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.companyName.$errors.length }">
            <label class="form-label">Företag</label>
            <input
              v-model="user.companyName"
              class="form-input"
              type="text"
            />
            <p
              v-for="(error, index) of v.user.companyName.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div
            class="form-group"
            :class="{ error: v.user.email.$errors.length }"
          >
            <label class="form-label">E-mail</label>
            <input
              v-model="user.email"
              class="form-input"
              type="text"
            />
            <p
              v-for="(error, index) of v.user.email.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.user.phone.$errors.length }">
            <label class="form-label">Telefon</label>
            <input
              v-model="user.phone"
              class="form-input"
              type="text"
            />
            <p
              v-for="(error, index) of v.user.phone.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group">
            <label class="form-label">Meddelande</label>
            <textarea rows="7" v-model="user.description" class="form-input" />
          </div>
        </div>
        <div class="form-group" :class="{ error: v.politic.$errors.length }">
          <label class="checkbox">
            <input
              v-model="politic"
              class="checkbox__input"
              type="checkbox"
              name="politic"
            />
            <div class="checkbox__icon"></div>
            <div class="checkbox__label">
              <span v-html="$store.state.options.other.legal_confirm"></span>
              <div class="info">
                <router-link class="link" target="_blank" to="/legal">
                  Läs allt om det här.
                </router-link>
              </div>
            </div>
          </label>
        </div>

        <div class="form__submit">
          <button
            :disabled="v.user.$invalid"
            class="btn btn--primary"
            type="submit"
            @click.prevent="submitHandler"
          >
            Skicka
          </button>
        </div>
        <p
          v-for="(error, index) of v.user.$errors"
          :key="index"
          class="error-message error"
        >
          {{ error.$message }}
        </p>
      </div>
    </div>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>
<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import { nextTick, onMounted, ref } from "vue";
import { helpers, required, numeric, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useMutation } from "@vue/apollo-composable";
import createClientApplicantMutation from "@/graphql/createClientApplicant.mutation.gql"
import ErrorPopup from "../components/popups/ErrorPopup.vue";

export default {
  components: {
    RandomBg,
    Header,
    ErrorPopup,
  },
  setup() {
    const imagesReady = ref(false);
    const progress = ref(0);
    const showErrorPopup = ref(false);
    const requiredErrorMessage = "Fältet får inte vara tomt";
    const emailErrorMessage = "E-postadressen är inte giltig";
    const numericErrorMessage = "Detta fält ska vara numeriskt";
    const checkboxRequiredRule = (value) => value === true;

    const { mutate: createClientApplicant } = useMutation(createClientApplicantMutation);
    const politic = ref(false);
    const user = ref({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      description: ""
    });

    const rules = {
      user: {
        firstName: {
          required: helpers.withMessage(requiredErrorMessage, required),
          $autoDirty: true,
        },
        lastName: {
          required: helpers.withMessage(requiredErrorMessage, required),
          $autoDirty: true,
        },
        companyName: {
          required: helpers.withMessage(requiredErrorMessage, required),
        },
        email: {
          required: helpers.withMessage(requiredErrorMessage, required),
          email: helpers.withMessage(emailErrorMessage, email),
          $autoDirty: true,
        },
        phone: {
          required: helpers.withMessage(requiredErrorMessage, (value) =>
            value ? true : false
          ),
          numeric: helpers.withMessage(numericErrorMessage, numeric),
          $autoDirty: true,
        },
      },
      politic: { checkboxRequiredRule, $autoDirty: true },
    };
    const v = useVuelidate(rules, {
      user,
      politic
    });
    onMounted(() => {
      setTimeout(() => {
        progress.value = 100;
        imagesReady.value = true;
      }, 200);
    });
    return {
      createClientApplicant,
      showErrorPopup,
      imagesReady,
      progress,
      user,
      politic,
      v,
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }

      nextTick(() => {
        this.createClientApplicant({
          attributes: this.user
        })
          .then(({ data }) => {
            if (!data.createClientApplicant.clientApplicant.id) {
              this.errorHandler("Något gick fel");
              return;
            }
            this.errorHandler("Din ansökan har mottagits. En bekräftelse kommer att skickas till dig efter verifiering.");
          })
          .catch((e) => {
            const message = e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  },
};
</script>
<style lang="scss" scoped>
.signup {
  &__wrap {
    margin-top: 26px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>
