<template>
  <div>
    <form class="form" id="form">
      <div class="form__container container">
        <div v-if="!currentUser.id || currentUser.role != 'model'">
          <h2 class="content-title page__title">{{ title }}</h2>
          <div class="page__description" v-html="$store.state.options.models.apply_text"></div>
          <h3 class="form__subtitle">{{ $store.state.options.models.contact_title }}</h3>
          <div class="form__block-description" v-html="$store.state.options.models.contact_text"></div>
          <div>
            <div class="form-group" :class="{ error: v.name.$errors.length }">
              <label class="form-label">Förnamn</label>
              <input
                v-model="name"
                class="form-input"
                type="text"
                placeholder="Kontaktpersonens förnamn"
              />
              <p
                v-for="(error, index) of v.name.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="form-group" :class="{ error: v.lastName.$errors.length }">
              <label class="form-label">Efternamn</label>
              <input
                v-model="lastName"
                class="form-input"
                type="text"
                placeholder="Kontaktpersonens efternamn"
              />
              <p
                v-for="(error, index) of v.lastName.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
            <div
              class="form-group"
              :class="{ error: v.emailAddress.$errors.length }"
            >
              <label class="form-label">E-mail</label>
              <input
                v-model="emailAddress"
                class="form-input"
                type="text"
                placeholder="Kontaktpersonens e-mail"
              />
              <p
                v-for="(error, index) of v.emailAddress.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
            <div class="form-group" :class="{ error: v.phone.$errors.length }">
              <label class="form-label">Telefon</label>
              <input
                v-model="phone"
                class="form-input"
                type="text"
                placeholder="Kontaktpersonens telefon"
              />
              <p
                v-for="(error, index) of v.phone.$errors"
                :key="index"
                class="error-message"
              >
                {{ error.$message }}
              </p>
            </div>
          </div>
        </div>
        <div v-for="(model, index) in models" :key="index" class="form__wrap">
          <model-form
            v-if="!resetForms"
            v-model:modelData="models[index]"
            :index="index"
            :submitClick="submitClick"
            :modelTypes="modelTypes"
            @show-popup="showPopupHandler"
            @delete="deleteItem"
            @image-added="imageAdded"
            @image-uploaded="imageUploaded"
          />
        </div>
        <div class="form__footer">
          <h3 class="form__subtitle">
            Klicka nedan om du vill lägga till en modell till
          </h3>
          <div class="form__block-description">
            Om du vill ansöka för en till modell, klicka nedan och följ instruktionerna. 
          </div>
          <div class="info">
            <a class="link" @click="addAnotherHandler">
              + Lägg till en modell till
            </a>
          </div>
          <h3 v-if="!Object.keys(currentUser).length" class="form__subtitle">
            Skicka in ansökan
          </h3>
          <div
            v-if="!Object.keys(currentUser).length"
            class="form-group"
            :class="{ error: v.politic.$errors.length }"
          >
            <label class="checkbox">
              <input
                v-model="politic"
                class="checkbox__input"
                type="checkbox"
                name="politic"
              />
              <div class="checkbox__icon"></div>
              <div class="checkbox__label">
                <span v-html="$store.state.options.other.legal_confirm"></span>
                <div class="info">
                  <router-link class="link" target="_blank" to="/legal">
                    Läs allt om det här.
                  </router-link>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div class="form__submit flex">
          <button
            v-if="Object.keys(currentUser).length"
            :disabled="submitClick"
            class="btn btn--primary"
            type="submit"
            @click.prevent="submitHandler"
          >
            Fortsätt
          </button>
          <button
            v-else
            :disabled="submitClick"
            class="btn btn--primary"
            type="submit"
            @click.prevent="submitHandler"
          >
            Skicka in
          </button>
        </div>
        <p
          v-for="(error, index) of v.$errors"
          :key="index"
          class="error-message error"
        >
          {{ error.$message }}
        </p>
      </div>
      <transition name="fade">
        <images-popup
          v-if="showPopup"
          :header="imagesHeader"
          :humanText="texts.humanText"
          :animalsText="texts.animalsText"
          :locationsText="texts.locationsText"
          :modelType="currentModelType"
          v-model:showPopup="showPopup"
        />
      </transition>
    </form>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import { nextTick, reactive, ref, computed } from "vue";
import { email, helpers, requiredIf, numeric } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ModelForm from "@/components/forms/ModelForm.vue";
import ImagesPopup from "@/components/popups/ImagesPopup.vue";
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import createModelMutation from "@/graphql/createModel.mutation.gql";
// import createAnimalMutation from "@/graphql/createAnimal.mutation.gql";
// import createLocationMutation from "@/graphql/createLocation.mutation.gql";
import uploadResourceImageMutation from "@/graphql/uploadResourceImage.mutation.gql";
import ApplyExistingResources from "@/graphql/applyExistingResources.mutation.gql";
import { useMutation } from "@vue/apollo-composable";
import { useStore } from "vuex";
export default {
  components: {
    ModelForm,
    ImagesPopup,
    ErrorPopup,
  },
  props: {
    title: String,
    imagesHeader: String,
    texts: Object,
    modelTypes: {
      type: Array,
      default: () => ["human", "animal", "location"],
    },
    castingID: Number,
    dataReady: Boolean
  },
  emits: [
    "sending",
    "update:showPopup",
    "added-new",
    "update:confirmation",
    "image-added",
    "image-uploaded"
  ],
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = computed(() => store.state.user);
    const name = ref("");
    const lastName = ref("");
    const emailAddress = ref("");
    const phone = ref("");
    const currentModelType = ref("");
    const politic = ref(false);
    const showPopup = ref(false);
    const submitClick = ref(false);
    const resetForms = ref(false);
    const { mutate: createModel } = useMutation(createModelMutation);
    const { mutate: uploadImage } = useMutation(uploadResourceImageMutation);
    const defaultModel = {
      modelType: "human",
      modelName: "",
      modelLastName: "",
      modelBirth: "",
      modelGender: "",
      modelHeight: "",
      images: []
    };
    const models = reactive([defaultModel]);
    const requiredErrorMessage = "Fältet får inte vara tomt";
    const emailErrorMessage = "E-postadressen är inte giltig";
    const numericErrorMessage = "Detta fält ska vara numeriskt";
    const checkboxRequiredRule = (value) => {
      if (Object.keys(currentUser.value).length) {
        return true;
      }
      return value === true;
    };
    const rules = {
      name: {
        required: helpers.withMessage(
          requiredErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        $autoDirty: true,
      },
      lastName: {
        required: helpers.withMessage(
          requiredErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        $autoDirty: true,
      },
      emailAddress: {
        required: helpers.withMessage(
          requiredErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true,
      },
      phone: {
        required: helpers.withMessage(
          requiredErrorMessage,
          requiredIf(() => !Object.keys(currentUser.value).length)
        ),
        numeric: helpers.withMessage(numericErrorMessage, numeric),
        $autoDirty: true,
      },
      politic: { checkboxRequiredRule, $autoDirty: true },
    };
    const v = useVuelidate(rules, {
      name,
      lastName,
      emailAddress,
      phone,
      politic,
    });
    const dataCounter = ref(0);
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const createdResources = ref([]);
    const { mutate: applyResources } = useMutation(ApplyExistingResources);
    function imageAdded(value) {
      emit("image-added", value);
    }
    function imageUploaded(value) {
      emit("image-uploaded", value);
    }
    return {
      name,
      lastName,
      emailAddress,
      phone,
      defaultModel,
      models,
      politic,
      showPopup,
      currentModelType,
      submitClick,
      resetForms,
      createModel,
      uploadImage,
      dataCounter,
      showErrorPopup,
      errorMessage,
      currentUser,
      createdResources,
      applyResources,
      imageAdded,
      imageUploaded,
      v,
    };
  },
  watch: {
    dataCounter() {
      if (this.dataCounter === this.models.length) {
        // RESET ALL
        this.resetForms = true;
        nextTick(() => {
          this.resetForms = false;
          this.dataCounter = 0;
          this.models = [this.defaultModel];
          this.createdResources = [];
          this.submitClick = false;
          // this.dataReady = false;
        });

        if (!this.castingID) {
          this.errorHandler("Din ansökan har mottagits. En bekräftelse kommer att skickas till dig efter verifiering.");
          this.$emit("sending", false);
          return;
        }
        if (!this.currentUser.id) {
          this.applyResources({
            input: {
              email: this.emailAddress,
              resourceIds: this.createdResources,
              castingId: this.castingID,
            },
          })
            .then(({ data }) => {
              if (data.createResourceCasting.errors) {
                this.errorHandler(data.createResourceCasting.errors.message);
                return;
              }
              this.submitClick = false;
              this.$emit("added-new", true);
              this.$emit("sending", false);
            })
            .catch((error) => {
              this.errorHandler(error.message);
            });
        } else {
          this.submitClick = false;
          this.$emit("added-new", true);
          this.$emit("sending", false);
        }
      }
    },
  },
  methods: {
    submitHandler() {
      this.submitClick = true;
      this.v.$touch();
      if (this.v.$error) {
        this.submitClick = false;
        return;
      }
      let data = [];
      nextTick(() => {
        this.$emit("sending", true);
        const checkDataReady = () => {
          if (!this.dataReady) {
            setTimeout(checkDataReady, 500);
          } else {
            data = this.convertData();
            data.dataModels.forEach((model, i) => {
              delete model.attributes.images;
              this.createModel({ input: model })
                .then((response) => {
                  const id = response?.data?.createModel?.resource?.id;
                  if (id) {
                    this.createdResources = [ ...this.createdResources, id ];
                  }
                  this.dataCounter++;
                  console.log(i);
                  this.models[i] = this.defaultModel;
                })
                .catch(e => {
                  this.errorHandler(e);
                });
            });
          }
        };
        checkDataReady();
      });
    },
    convertData() {
      let data = {};
      let dataModels = [];
      let dataAnimals = [];
      let dataLocations = [];
      this.models.forEach(item => {
        dataModels.push({
          attributes: {
            contactFirstName: this.currentUser.role == 'model' ? this.currentUser.firstName : this.name,
            contactLastName: this.currentUser.role == 'model' ? this.currentUser.lastName : this.lastName,
            email: this.currentUser.role == 'model' ? this.currentUser.email : this.emailAddress,
            phone: this.currentUser.role == 'model' ? this.currentUser.phone : this.phone,
            locationName: item.modelAddress,
            resourceImageIds: item.resourceImageIds,
            resourceModelAttributes: {
              firstName: item.modelName,
              lastName: item.modelLastName,
              dob: item.modelBirth,
              gender: item.modelGender,
              height: +item.modelHeight
            }
          }
        });
      });
      data = {
        dataModels: dataModels,
        dataAnimals: dataAnimals,
        dataLocations: dataLocations
      };
      return data;
    },
    // addAttachment(array) {
    //   return new Promise((resolve, reject) => {
    //     let results = [];
    //     array.forEach(element => {
    //       this.uploadImage({ file: element })
    //         .then(response => {
    //           results.push(response.data.uploadResourceImage.resourceImage.id);
    //           if (results.length === array.length) {
    //             resolve(results);
    //           }
    //         })
    //         .catch(error => {
    //           reject(error);
    //         });
    //     });
    //   });
    // }
    errorHandler(error) {
      this.$emit("sending", false);
      this.submitClick = false;
      this.showErrorPopup = true;
      this.errorMessage = error;
    },
    addAnotherHandler() {
      if (this.models.length < 8) {
        this.models.push(this.defaultModel);
      }
    },
    showPopupHandler(modelType) {
      if (modelType) {
        this.currentModelType = modelType;
      }
      this.showPopup = true;
      document.body.classList.add("overflow");
    },
    deleteItem(index) {
      this.models.splice(index, 1);
    },
  }
};
</script>

<style lang="scss">
@import "~@/assets/style/views/home/form.scss";
.form {
  // @media screen and (max-width: 767px) {
  //   padding-top: 32px;
  // }
  // &__title {
  //   @media screen and (max-width: 767px) {
  //     padding-top: 0;
  //   }
  // }
}
</style>
