<template>
  <div class="page">
    <RandomBg whiteBg="$store.state.projectName === 'miine'" />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        <!-- Loading <span>{{ Math.round(progress) }}%</span> -->
        Loading <span>...</span>
      </div>
    </transition>
    <Header />
    <form class="form" id="form" v-if="$store.state.options">
      <div class="form__container container">
        <h1 class="content-title page__title">{{ $store.state.options.auth.forgot_title }}</h1>
        <div class="page__description" v-html="$store.state.options.auth.forgot_text"></div>
        <div>
          <div class="form-group" :class="{ error: v.emailAddress.$errors.length }">
            <label class="form-label">E-mail</label>
            <input
              v-model="emailAddress"
              class="form-input"
              type="text"
              placeholder="Fyll i e-mail"
            />
            <p
              v-for="(error, index) of v.emailAddress.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="form__submit">
          <button
            :disabled="v.$invalid"
            class="btn btn--primary"
            type="submit"
            @click.prevent="submitHandler"
          >
            Återställ
          </button>
          <div class="info">
            <router-link class="link" :to="'/login' + ($store.state.projectName === 'miine' ? '?from=miine' : '')">
              Logga In
            </router-link>
          </div>
        </div>
      </div>
    </form>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import useVuelidate from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import { nextTick, onMounted, ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import requestResetPasswordMutation from "@/graphql/requestResetPassword.mutation.gql";
export default {
  components: {
    RandomBg,
    Header,
    ErrorPopup,
  },
  setup() {
    const isPageReady = ref(false);
    const emailAddress = ref("");
    const emailRequiredErrorMessage = "Fältet får inte vara tomt";
    const emailErrorMessage = "E-postadressen är inte giltig";
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const { mutate: requestResetPassword } = useMutation(requestResetPasswordMutation);
    const rules = {
      emailAddress: {
        required: helpers.withMessage(emailRequiredErrorMessage, value =>
          value ? true : false
        ),
        email: helpers.withMessage(emailErrorMessage, email),
        $autoDirty: true
      },
    };
    const v = useVuelidate(rules, {
      emailAddress
    });
    onMounted(() => {
      setTimeout(() => {
        isPageReady.value = true;
      }, 200);
    });

    return {
      isPageReady,
      emailAddress,
      showErrorPopup,
      errorMessage,
      requestResetPassword,
      v,
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }
      nextTick(() => {
        this.requestResetPassword({
          input: {
            email: this.emailAddress,
            redirectUrl: `${process.env.VUE_APP_DOMAIN}/reset-password`
          }
        })
          .then(({ data }) => {
            if (!data.requestResetPassword.passwordReset) {
              this.errorHandler("Något gick fel");
              return;
            }
            this.errorHandler(data.requestResetPassword.passwordReset);
          })
          .catch(e => {
            const message = e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/login/login.scss";
</style>
