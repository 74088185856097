<template>
  <div class="page">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        <!-- Loading <span>{{ Math.round(progress) }}%</span> -->
        Loading <span>...</span>
      </div>
    </transition>
    <Header />
    <form class="form" id="form" v-if="$store.state.options">
      <div class="form__container container">
        <h1 class="content-title page__title">{{ pageTitle }}</h1>
        <div class="page__description" v-html="pageText"></div>
        <div>
          <div class="form-group" :class="{ error: v.password.$errors.length }">
            <label class="form-label">Lösenord</label>
            <input
              v-model="password"
              class="form-input"
              type="password"
              placeholder="Enter password"
            />
            <p
              v-for="(error, index) of v.password.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
          <div class="form-group" :class="{ error: v.passwordConfirmation.$errors.length }">
            <label class="form-label">Lösenordsbekräftelse</label>
            <input
              v-model="passwordConfirmation"
              class="form-input"
              type="password"
              placeholder="Enter password again"
            />
            <p
              v-for="(error, index) of v.passwordConfirmation.$errors"
              :key="index"
              class="error-message"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="form__submit">
          <button
            :disabled="v.$invalid"
            class="btn btn--primary"
            type="submit"
            @click.prevent="submitHandler"
          >
            Skicka in
          </button>
          <div class="info">
            <router-link class="link" to="/login">Logga In</router-link>
          </div>
        </div>
      </div>
    </form>
    <teleport to="[data-error-popup]">
      <error-popup
        v-model:showPopup="showErrorPopup"
        :errorMessage="errorMessage"
        :white="true"
      />
    </teleport>
  </div>
</template>

<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import ErrorPopup from "@/components/popups/ErrorPopup.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { nextTick, onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useMutation } from "@vue/apollo-composable";
import resetPasswordMutation from "@/graphql/resetPassword.mutation.gql";
export default {
  components: {
    RandomBg,
    Header,
    ErrorPopup,
  },
  setup() {
    const isPageReady = ref(false);
    const store = useStore();
    const password = ref("");
    const passwordConfirmation = ref("");
    const passwordErrorMessage = "Password field cannot be empty";
    const passwordConfirmationErrorMessage = "Password confirmation field cannot be empty";
    const passwordConfirmationSameErrorMessage = "Password confirmation should be the same as password";
    const showErrorPopup = ref(false);
    const errorMessage = ref("");
    const { mutate: resetPassword } = useMutation(resetPasswordMutation);
    const rules = {
      password: {
        required: helpers.withMessage(passwordErrorMessage, required),
        containsPasswordRequirement: helpers.withMessage(
          () => `The password requires an uppercase, lowercase, number and a special character`,
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?$%^&*-])/.test(value)
        ),
        $autoDirty: true,
      },
      passwordConfirmation: {
        required: helpers.withMessage(passwordConfirmationErrorMessage, required),
        sameAsPassword: helpers.withMessage(
          passwordConfirmationSameErrorMessage,
          (value) => password.value === value
        ),
        $autoDirty: true,
      }
    };
    const v = useVuelidate(rules, {
      password,
      passwordConfirmation,
    });
    const route = useRoute();
    const pageTitle = computed(() =>
      route.name == "SetPassword"
        ? store.state.options.auth.set_title
        : store.state.options.auth.reset_title
    );
    const pageText = computed(() =>
      route.name == "SetPassword"
        ? store.state.options.auth.set_text
        : store.state.options.auth.reset_text
    );
    const successMessage =
      route.name == "SetPassword"
        ? "Registreringen är klar. Använd inloggningssidan för att logga in."
        : "Lösenordsåterställning lyckades. Använd ditt nya lösenord för att logga in.";
    onMounted(() => {
      console.log(route);
      setTimeout(() => {
        isPageReady.value = true;
      }, 200);
    });

    return {
      isPageReady,
      password,
      passwordConfirmation,
      resetPassword,
      showErrorPopup,
      errorMessage,
      v,
      pageTitle,
      pageText,
      successMessage,
    };
  },
  methods: {
    submitHandler() {
      this.v.$touch();
      if (this.v.$error) {
        return;
      }

      const resetPasswordToken = this.$route.query.reset_password_token;

      if (!resetPasswordToken) {
        this.errorHandler("Denna länk är inte giltig eller har upphört att gälla. Vänligen begär ett nytt lösenord med funktionen \"glömt lösenordet\" och följ länken i e-postmeddelandet. Kontakta supporten om du fortfarande har problem.")
        return;
      }

      nextTick(() => {
        this.resetPassword({
          input: {
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            resetPasswordToken
          }
        })
          .then(({ data }) => {
            if (!data.resetPassword.user) {
              this.errorHandler("Något gick fel");
              return;
            }
            this.errorHandler(this.successMessage);
          })
          .catch((e) => {
            const message =
              this.$route.name == "SetPassword"
                ? "Registreringen misslyckades. Den här användaren är redan registrerad eller så måste du be administratören skicka om registreringslänken och försöka igen."
                : e.networkError?.result?.errors?.map(error => error.message)?.join(', ') || e.message;
            this.errorHandler(message);
          });
      });
    },
    errorHandler(error) {
      this.showErrorPopup = true;
      this.errorMessage = error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/login/login.scss";
</style>
