<template>
  <div v-if="content" class="how-works">
    <div class="container how-works__container">
      <h2 class="how-works__title content-title" v-html="content.title"></h2>
      <div class="how-works__content row">
        <div class="col-2 col-sm-12"></div>
        <div class="col-8 col-sm-12">
          <div class="how-works__text" v-html="content.text"></div>
          <!-- <router-link class="content-link how-works__link" to="/">Read more</router-link> -->
        </div>
        <div class="col-2 col-sm-12"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object
  },
  setup () {
    

    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/views/home/howWorks.scss";
</style>

<style lang="css">
/* @media screen and (max-width: 1300px) {
  .how-works__text br {
    display: none;
  }
} */
</style>