import { createStore } from "vuex";

export default createStore({
  state: {
    pageHome: {},
    portfolios: [],
    user: {},
    meta: {},
    options: false,
    projectName: "miini",
  },
  mutations: {
    fetchPage: (state, page) => (state["page"] = page),
    fetchHome: (state, page) => (state.pageHome = page),
    fetchPortfolios: (state, portfolios) => (state.portfolios = portfolios),
    fetchProject: (state, project) => (state["project"] = project),
    fetchUser: (state, user) => (state.user = user),
    fetchMeta: (state, meta) => (state.meta = meta),
    fetchOptions: (state, options) => (state.options = options),
    fetchProjectName: (state, projectName) => (state.projectName = projectName),
  },
  actions: {
    onFetchHome: async ({ commit }) => {
      const response = await fetch(
        `${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/pages?slug=home`
      ).then((res) => res.json());
      commit("fetchHome", response[0]);
    },
    onFetchPage: async ({ commit }, slug) => {
      const response = await fetch(
        `${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/pages?slug=${slug}`
      ).then((res) => res.json());
      commit("fetchPage", response[0]);
    },
    onFetchMeta: async ({ commit }) => {
      const response = await fetch(
        `${process.env.VUE_APP_WP_URL}/wp-json/miini/v2/meta`
      ).then((res) => res.json());
      commit("fetchMeta", response);
    },
    onFetchOptions: async ({ commit }) => {
      const response = await fetch(
        `${process.env.VUE_APP_WP_URL}/wp-json/miini/v2/options`
      ).then((res) => res.json());
      commit("fetchOptions", response);
    },
    onFetchPortfolios: async ({ commit }) => {
      try {
        let response = await fetch(
          `${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/posts?order=desc&_embed=1`
        ).then((res) => res.json());
        response = response.filter((el) => el.status === "publish");
        commit("fetchPortfolios", response);
      } catch (error) {
        commit("fetchPortfolios", []);
        console.log(error);
      }
    },
    onFetchProject: async ({ commit }, slug) => {
      console.log(slug);
      const response = await fetch(
        `${process.env.VUE_APP_WP_URL}/wp-json/wp/v2/posts?slug=${slug}`
      ).then((res) => res.json());
      commit("fetchProject", response[0]);
    },
    onFetchUser: ({ commit }, user) => {
      commit("fetchUser", user);
    },
    checkAuth: ({ commit }) => {
      const user = window.localStorage.getItem("user");
      if (user) {
        commit("fetchUser", JSON.parse(user));
      }
    },
    onFetchProjectName: ({ commit }, projectName) => {
      commit("fetchProjectName", projectName);
    },
  },
  modules: {},
});
