<template>
  <div v-if="popupStatus" class="popup error-popup" :class="{white: white}">
    <div class="popup__wrap">
      <button type="button" class="popup__close" @click="hidePopup">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.556"
          height="15.556"
          viewBox="0 0 15.556 15.556"
        >
          <rect
            width="3"
            height="19"
            transform="translate(0 2.121) rotate(-45)"
          />
          <rect
            width="3"
            height="19"
            transform="translate(2.121 15.556) rotate(-135)"
          />
        </svg>
      </button>
      <div class="popup__container">
        <div v-if="contentText" class="popup__content">
          <h3 class="popup__title" v-if="title">{{ title }}</h3>
          <div class="popup__text" v-html="contentText"></div>
        </div>
        <div v-else class="popup__content">
          {{ errorMessage }}
        </div>
        <div class="popup__confirmation">
          <button class="btn btn--primary" type="button" @click="hidePopup">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue";
export default {
  props: {
    errorMessage: String,
    showPopup: Boolean,
    content: {
      type: String,
      default: ''
    },
    title: String,
    white: Boolean,
    callback: {
      type: [Function, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const popupStatus = computed({
      get: () => props.showPopup,
      set: value => emit("update:showPopup", value)
    });
    const contentText = computed(() => (props.content.replace(/<p>|<\/p>/g, "")));
    watch(
      () => props.showPopup,
      newValue => {
        if (newValue) {
          document.body.classList.add("overflow");
        }
      }
    )
    return {
      popupStatus,
      contentText
    };
  },
  methods: {
    hidePopup() {
      this.popupStatus = false;
      document.body.classList.remove("overflow");
      if (this.callback) {
        this.callback();
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/style/components/popup.scss";
</style>