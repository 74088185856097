<template>
  <Job :presetJob="job" :id="job.id" v-if="job && Object.keys(job).length"/>
  <div class="page" v-else>
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!isPageReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!isPageReady" class="loader-text">
        <!-- Loading <span>{{ Math.round(progress) }}%</span> -->
        Loading <span>...</span>
      </div>
    </transition>
    <Header />
    <div v-if="page && page.acf" class="page__container container">
      <h1 class="content-title page__title">{{ page.acf.title }}</h1>
      <div v-html="page.acf.content" class="page__content"></div>

      <Faq v-if="page && page.acf.faq" :categories="page.acf.faq" />
    </div>
    <NotFound v-else />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";

import jobQuery from "@/graphql/job.query.gql";

import RandomBg from "@/components/RandomBg.vue";
import Header from "@/components/Header.vue";
import Faq from "@/components/Faq.vue";
import NotFound from "@/components/NotFound.vue"
import Job from "./Job.vue";

export default {
  components:{
    RandomBg,
    Header,
    Faq,
    NotFound,
    Job,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const pageSlug = route.params.slug;
    const page = computed(() => store.state.page);
    const isPageReady = ref(false);
    const links = ref([]);

    const variables = reactive({
      slug: pageSlug,
    });
    const { result } = useQuery(jobQuery, variables, {
      fetchPolicy: "cache-and-network",
    });
    const job = useResult(result, [], (data) => data.casting);

    onMounted(() => {
      setTimeout(() => {
        isPageReady.value = true;
      }, 200);
      store.dispatch("onFetchPage", pageSlug);
    });

    return {
      job,
      links,
      page,
      isPageReady,
    };
  }
};
</script>
