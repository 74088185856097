<template>
  <div class="page">
    <RandomBg />
    <transition name="miiniLoader">
      <div v-if="!imagesReady" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="!imagesReady" class="loader-text">
        Loading <span>{{ Math.round(progress) }}%</span>
      </div>
    </transition>
    <transition name="fadetext">
      <div v-if="sendingData" class="loader"></div>
    </transition>
    <transition name="fadetext">
      <div v-if="sendingData" class="loader-progress">
        <div
          class="loader-progress__info"
          :style="addedImages ? '' : 'justify-content:center;'"
        >
          <label>Vänta, laddar upp </label>
          <label v-if="addedImages">
            {{ Math.round((100 * uploadedImages) / addedImages) }}%
          </label>
        </div>
        <div v-if="addedImages" class="loader-progress__background">
          <div
            class="loader-progress__tracker"
            :style="`width: ${(100 * uploadedImages) / addedImages}%`"
          ></div>
        </div>
      </div>
    </transition>
    <Header />
    <apply-form
      v-if="homePage.acf && $store.state.options"
      :title="$store.state.options.models.apply_title"
      :imagesHeader="homePage.acf.imagesPopupHeader"
      :texts="homePage.acf.imagesPopupTexts"
      :modelTypes="['human']"
      :dataReady="dataReady"
      @sending="sendingHandler"
      @image-added="imageAdded"
      @image-uploaded="imageUploaded"
    />
  </div>
</template>
<script>
import RandomBg from "@/components/RandomBg.vue";
import Header from '@/components/Header.vue';
import ApplyForm from '@/components/forms/ApplyForm.vue';
import { onMounted, ref, computed } from "vue";
import { useStore } from 'vuex';
export default {
  components: {
    RandomBg,
    Header,
    ApplyForm,
  },
  setup() {
    const imagesReady = ref(false);
    const progress = ref(0);
    const store = useStore();
    const homePage = computed(() => store.state.pageHome);
    const dataReady = ref(false);
    const sendingData = ref(false);
    const addedImages = ref(0);
    const uploadedImages = ref(0);

    onMounted(() => {
      store.dispatch("onFetchHome");
      setTimeout(() => {
        progress.value = 100;
        imagesReady.value = true;
      }, 200)
    });
    return {
      imagesReady,
      progress,
      homePage,
      sendingData,
      dataReady,
      addedImages,
      uploadedImages,
    };
  },
  methods: {
    sendingHandler(event) {
      this.sendingData = event;
      if (this.sendingData && !this.addedImages) {
        this.dataReady = true;
      }
    },
    imageAdded(value) {
      this.addedImages += value;
      if (this.uploadedImages !== this.addedImages) {
        this.dataReady = false;
      }
    },
    imageUploaded(value) {
      this.uploadedImages += value;
      if (this.uploadedImages === this.addedImages) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.dataReady = true;
          }, 300);
        });
      }
    },

  }
};
</script>
